import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid, Button
} from '@mui/material';
import { 
  ArrowBack
} from '@mui/icons-material';

import SupplierExtractedFields from './SupplierExtractedFields';

const SupplierPage = ({
  detailedData, editableData, tempEditableData, setTempEditableData, sites, suppliers, nominalCodes, docStatuses, 
  currentImageIndex, isEdited, zoomEnabled, transformWrapperRef, handleBackClick, handleFieldChange, 
  handleFieldBlur, handlePreviousImage, handleNextImage, handleZoomToggle, handleSaveChanges, 
 otherDocuments, handleCardClick, getLocationOptionLabel, 
  getSupplierOptionLabel, getNominalCodeOptionLabel, refetchData, imageName, advanceToNextDocument
}) => {
  const [validationErrors, setValidationErrors] = useState({});


  const validateAllFields = useCallback(() => {
    let errors = {};
    const requiredFields = ['supplier_id', 'name'];
    requiredFields.forEach(field => {
      if (!tempEditableData[field]) {
        errors[field] = 'This field is required';
      }
    });
    setValidationErrors(errors);
  }, [tempEditableData]);

  useEffect(() => {
    validateAllFields();
  }, [tempEditableData, validateAllFields]);

  return (
    <div style={{ paddingLeft: '200px' ,  paddingRight: '200px' }}>
      <Grid container spacing={2} style={{ marginBottom: '10px' }} alignItems="center">
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button variant="outlined" startIcon={<ArrowBack />} onClick={handleBackClick}>
            Return to Grid
          </Button>
        </Grid>
      </Grid>

      <Grid  alignItems="right">
        <Grid item xs={1} md={4} alignItems="right">
          <SupplierExtractedFields
            tempEditableData={tempEditableData}
            setTempEditableData={setTempEditableData}
            suppliers={suppliers}
            handleFieldChange={handleFieldChange}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            isEdited={isEdited}
            handleSaveChanges={handleSaveChanges}
            getSupplierOptionLabel={getSupplierOptionLabel}
            refetchData={refetchData}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SupplierPage;