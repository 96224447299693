// LoginPage.js
import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Checkbox, FormControlLabel, Typography, Paper, Alert } from '@mui/material';

function LoginPage() {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const { isAuthenticated, userRole } = useAuth();
  useEffect(() => {
    
    // console.log(isAuthenticated, userRole);
    if (isAuthenticated) {
      if (userRole === 'upload') {
        // console.log(isAuthenticated, userRole);
        navigate('/upload');
      
      } else if (userRole === 'review' || userRole === 'admin') {
        // console.log(isAuthenticated, userRole);
        navigate('/review');
      
      }
      
    }
  }, []);
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const { success, role } = login(username, password, rememberMe);
    if (success) {
      if (role === 'upload') {
        navigate('/upload');
      
      } else if (role === 'review' || role === 'admin') {
        navigate('/review');
      
      }
    } else {
      setError('Invalid username or password.');
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="80vh" bgcolor="#f5f5f5">
      <Paper elevation={3} style={{ padding: '40px', maxWidth: '400px', width: '100%' }}>
        <Typography variant="h4" component="h1" gutterBottom style={{ marginTop: '24px' }}>
          Login
        </Typography>

        {error && <Alert severity="error" style={{ marginBottom: '20px' }}>{error}</Alert>}

        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            margin="normal"
            required
          />

          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            required
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                color="primary"
              />
            }
            label="Remember Me"
          />

          <Button variant="contained" color="primary" type="submit" fullWidth style={{ marginTop: '20px' }}>
            Login
          </Button>
        </form>
      </Paper>
    </Box>
  );
}

export default LoginPage;