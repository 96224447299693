import React, { useState, useEffect, useCallback } from 'react';
import heic2any from 'heic2any';
import Tesseract from 'tesseract.js';
import {
  Typography,
  Card,
  CardContent,
  TextField,
  Tooltip,
  Button,
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  CircularProgress,
  Alert,
  Snackbar,
  Grid,
  Dialog,
  DialogContent
} from '@mui/material';
import {
  Save as SaveIcon,
  Clear as ClearIcon
} from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { styled } from '@mui/system';
import { isMobile } from 'react-device-detect';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Close as CloseIcon } from '@mui/icons-material';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

const DropzoneContainer = styled('div')({
  border: '2px dashed #ccc',
  borderRadius: '8px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'border-color 0.3s',
  '&:hover': {
    borderColor: '#3f51b5',
  },
});

const Thumbnail = styled('div')({
  position: 'relative',
  margin: '10px',
  textAlign: 'center',
  '& img': {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  '& .loadingOverlay': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
  },
});

const ThumbnailPDF = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  margin: '10px',
  textAlign: 'center',
  '& .pdf-icon': {
    width: '100px',
    height: '100px',
    backgroundColor: '#e0e0e0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    fontSize: '1.5rem',
    color: '#3f51b5',
  },
  '& .icon-button': {
    position: 'absolute', 
    top: '-10px', 
    right: '-10px', 
    color: 'red',
  }
});


// Extracted Fields Component
const SupplierExtractedFields = ({
  tempEditableData,
  setTempEditableData,
  suppliers,
  handleFieldChange,
  validationErrors,
  setValidationErrors,
  isEdited,
  handleSaveChanges,
  getSupplierOptionLabel,
  refetchData
}) => {
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState({ type: '', message: '' });
  
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [analysisError, setAnalysisError] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [supplierNameDisabled, setSupplierNameDisabled] = useState(false);
  const [topFilteredOption, setTopFilteredOption] = useState(null);
  const [filterValue, setFilterValue] = useState(null);


  const checkImageForText = async (imageSrc) => {
    try {
      const { data: { words } } = await Tesseract.recognize(imageSrc, 'eng', {
        tessedit_pageseg_mode: Tesseract.PSM.SINGLE_BLOCK,
        tessedit_ocr_engine_mode: Tesseract.OEM.LSTM_ONLY,
      });

      const confidenceThreshold = 80; // Adjust this value as needed
      const filteredWords = words.filter((word) => word.confidence >= confidenceThreshold);
      const filteredText = filteredWords.map((word) => word.text).join(' ');

      return filteredText.length >= 50;
    } catch (err) {
      console.error('Error during OCR processing:', err);
      return false;
    }
  };


  const checkAllImagesLoaded = useCallback(() => {
    const allLoaded = images.every(image => !image.isLoading);
    setAllImagesLoaded(allLoaded);

  }, [images]);

  useEffect(() => {
    if (images.length > 0) {
      checkAllImagesLoaded();
    }
  }, [images, checkAllImagesLoaded]);

  useEffect(() => {
    if (tempEditableData?.id && tempEditableData?.name !== null && tempEditableData?.name !== "") {
      setSupplierNameDisabled(true);
    } else {
      setSupplierNameDisabled(false);
    }
    localStorage.removeItem('uploads');
    const storedData = JSON.parse(localStorage.getItem('uploads')) || [];
    setImages(storedData);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/heic': ['.heic'],
      'application/pdf': ['.pdf'],
    },
    onDrop: async (acceptedFiles) => {
      setResponseData(null);

      const convertedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          if (file.type === 'image/heic' || file.name.endsWith('.heic')) {
            try {
              setSuccess(true);
              setResponseMessage(`Converting HEIC to JPEG image.`);
              const blob = await heic2any({ blob: file, toType: 'image/jpeg' });
              setSuccess(false);
              return new File([blob], file.name.replace(/\.heic$/i, '.jpg'), {type: 'image/jpeg'});
            
            } catch (error) {
              setSuccess(false);
              console.error('Error converting HEIC to JPEG:', error);
              setError(`Error converting HEIC to JPEG: ${JSON.stringify(error)}`)
              return null;
              
            }
          }
          setSuccess(false);
          return file;
        })
      );

      const newImages = convertedFiles.filter(i => i).map((file) => ({
        file,
        preview: file.type === 'application/pdf' ? '' : URL.createObjectURL(file),
        isLoading: file.type !== 'application/pdf',
        error: null,
      }));

      setImages((prevImages) => [...prevImages, ...newImages]);

      for (const newImage of newImages) {
        if (newImage.file.type !== 'application/pdf') {
          const isValid = await checkImageForText(newImage.preview);
          if (!isValid) {
            setImages((prevImages) => prevImages.filter((image) => image !== newImage));
            setError('One or more images do not contain enough text or are too blurry.');
          } else {
            newImage.isLoading = false;
            setImages((prevImages) => [...prevImages]);
          }
        } else {
          newImage.isLoading = false;
          setImages((prevImages) => [...prevImages]);
        }
      }
      // Once all new images are processed, check if all images are done loading
      checkAllImagesLoaded();
    },
  });

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleDeleteImage = (imageToDelete) => {
    const updatedImages = images.filter((image) => image !== imageToDelete);
    setImages(updatedImages);
    localStorage.setItem('uploads', JSON.stringify(updatedImages));
    setAllImagesLoaded(updatedImages.length !== 0);
  };

  const setFieldError = (field, error) => {
    setValidationErrors((prev) => ({ ...prev, [field]: error }));
  };

  const clearFieldError = (field) => {
    setValidationErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[field];
      return newErrors;
    });
  };


  const handleFocusEvent = () => {
    setTopFilteredOption(null);
    setFilterValue(null);
  };


  // Filter Options
  const filterOptions = (options, state, isSupplier) => {
    const inputValue = state.inputValue.toLowerCase();
    setFilterValue(inputValue);
    if (isSupplier) {
      options = options.filter(
        (option) =>
          option.supplier_id?.toLowerCase().includes(inputValue) ||
          option.name?.toLowerCase().includes(inputValue)
      );
      if (options?.length > 0) {
        setTopFilteredOption(options[0]);
      }
      return options;
    }

    const startsWithOptions = options.filter(
      (option) =>
        option.location_id?.toString().toLowerCase().startsWith(inputValue) ||
        option.name?.toLowerCase().startsWith(inputValue)
    );

    if (startsWithOptions.length > 0) {
      setTopFilteredOption(startsWithOptions[0]);
      return startsWithOptions;
    }

    options = options.filter(
      (option) =>
        option.location_id?.toString().toLowerCase().includes(inputValue) ||
        option.name?.toLowerCase().includes(inputValue)
    );
    
    if (options?.length > 0) {
      setTopFilteredOption(options[0]);
    }

    return options
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents default form submission behavior

    const formData = new FormData();
    for (let index=0; index <images.length; index++) {
      formData.append(`image${index}`, images[index].file);
    }
    
    // Log form data for debugging
    // for (const pair of formData.entries()) {
    //   console.log(`${pair[0]}: ${pair[1]}`);
    // }

    setUploading(true);
    setAnalysisError('');
    setResponseData(null);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/v1/doc/text`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      });

      if (response.status === 200) {
        setSuccess(true);
        setResponseMessage(response.data.message || 'File/Image(s) processed successfully!');
        setResponseData(response.data.data);

        const storedData = JSON.parse(localStorage.getItem('uploads')) || [];
        const newUploads = images.map((image, index) => ({
          preview: image.preview,
          data: response.data,
        }));
        setTempEditableData(
          {
            supplier_id: response.data.data.supplier_id,
            name: response.data.data.supplier_name,
            telephone: response.data.data.supplier_telephone,
            email: response.data.data.supplier_email,
            website: response.data.data.supplier_website
          }
        );
        setSupplierNameDisabled(response.data.data.supplier_name !== null && response.data.data.supplier_name !== "")


        const updatedData = [...storedData, ...newUploads];
        localStorage.setItem('uploads', JSON.stringify(updatedData));
        allFieldsFilled();
        // setImages([]);
        // setSelectedImage(null);
        // clearJobNumber(); // Clear the job number after successful submission
      }

    } catch (err) {
      if (err.response && err.response.status === 422) {
        setError('One or more images are too blurry.');
        if (err.response.data && err.response.data.data) {
          const blurryFiles = err.response.data.data.map((file) => file.filename).join(', ');
          setAnalysisError(`The following images are too blurry: ${blurryFiles}`);
        }
      } else {
        console.error(err);
        setError(`Failed to process image(s). ${err?.response?.data?.error}`);
        
      }

    } finally {
      setUploading(false);

    }
  };


  

  // Validate Field
  const validateField = (field, value) => {
    if (['supplier_id', 'name', 'telephone', 'email', 'website'].includes(field)) {
      if (['supplier_id', 'name'].includes(field)) {
        if (!value && value !== 0) {
          setFieldError(field, 'This field is required');
        }
      } else {
        clearFieldError(field);
      }
    }
  };

  // Capitalize Field
  const capitalizeField = (field) => {
    return field
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // Check if all fields are filled
  const allFieldsFilled = () => {
    const requiredFields = [
      'supplier_id',
      'name'
    ];

    for (const field of requiredFields) {
      if (!tempEditableData[field] && tempEditableData[field] !== 0) {
        return false;
      }
    }
    return Object.keys(validationErrors).length === 0;
  };

  useEffect(() => {
    const requiredFields = [
      'supplier_id',
      'name'
    ];

    requiredFields.forEach((field) => {
      validateField(field, tempEditableData[field]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    if (allFieldsFilled()) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationErrors]);

  const handleSave = async () => {
    setLoading(true);
    try {
      await handleSaveChanges();
      setStatusMessage({ type: 'success', message: 'Changes saved successfully.' });
      setIsSaved(true);
      // await advanceToNextDocument();
    } catch (error) {
      setStatusMessage({ type: 'error', message: 'Failed to save changes. Please try again.' });
    } finally {
      setSupplierNameDisabled(false);
      setLoading(false);
      refetchData(); // Optional: refetch data after save, if applicable
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Tab' && filterValue) {
      if (topFilteredOption?.supplier_id) {
        tempEditableData.supplier_id = topFilteredOption.supplier_id;
        handleFieldChange('supplier_id', topFilteredOption.supplier_id);
        validateField('supplier_id', topFilteredOption.supplier_id);
        setIsSaved(false); // Reset isSaved on change
      }
      
      //event.preventDefault(); // Prevent default Tab behavior
    }
  };

  // Render
  return ( 
    <> 
      <Card> 
        <CardContent>
          {loading ? (
            <Box display="flex" justifyContent="center" my={3}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography variant="h5" style={{ flexGrow: 1 }}>
                {tempEditableData?.id? 'Update': 'Create'}  Supplier Mapping
              </Typography>
              {
                !tempEditableData?.id? <div style={{ padding: '20px' }}> 
                  <form onSubmit={handleSubmit}>
                    <div style={{ marginTop: '20px', position: 'relative' }}>
                      <DropzoneContainer {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Typography variant="body1">
                          {isMobile ? 'Take a picture or upload a PDF' : "Drag 'n drop or click to Upload a File"}
                        </Typography>
                      </DropzoneContainer>

                      {analysisError && (
                        <Alert severity="error" style={{ marginTop: '20px' }}>
                          {analysisError}
                        </Alert>
                      )}

                      <Grid container spacing={2} style={{ marginTop: '20px' }}>
                        {images.map((image) => (
                          <Grid item key={image.file.name} xs={12} sm={6} md={4} lg={3}>
                            {image.file.type === 'application/pdf' ? (
                              <ThumbnailPDF>
                                <div className="pdf-icon">PDF</div>
                                <Typography variant="caption" display="block" gutterBottom>
                                  {image.file.name}
                                </Typography>
                                <IconButton
                                  style={{ position: 'absolute', top: 0, right: 0, color: 'red' }}
                                  onClick={() => handleDeleteImage(image)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </ThumbnailPDF>
                            ) : (
                              <Thumbnail>
                                <img src={image.preview} alt="preview" onClick={() => handleImageClick(image.preview)} />
                                {image.isLoading && (
                                  <div className="loadingOverlay">
                                    <CircularProgress />
                                  </div>
                                )}
                                <Typography variant="caption" display="block" gutterBottom>
                                  {image.file.name}
                                </Typography>
                                <IconButton
                                  style={{ position: 'absolute', top: 0, right: 0, color: 'red' }}
                                  onClick={() => handleDeleteImage(image)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Thumbnail>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                      <Button
                        type="submit" // Add this to trigger form submission on Enter key press
                        variant="contained"
                        color="primary"
                        disabled={uploading || !allImagesLoaded || images.length === 0}
                        style={{ marginTop: '20px' }}
                      >
                        {uploading ? <CircularProgress size={24} /> : 'Submit'}
                      </Button>
                    </div>
                  </form>
                </div>:null
              }
              <div>
                {success && (
                  <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
                    <Alert onClose={() => setSuccess(false)} severity="success">
                      {responseMessage}
                    </Alert>
                  </Snackbar>
                )}
                {error && (
                  <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                    <Alert onClose={() => setError('')} severity="error">
                      {error}
                    </Alert>
                  </Snackbar>
                )}

                <Dialog open={!!selectedImage} onClose={handleCloseModal} maxWidth="lg" fullWidth>
                  <DialogContent style={{ position: 'relative' }}>
                    <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={handleCloseModal}>
                      <CloseIcon />
                    </IconButton>
                    {selectedImage && (
                      <img src={selectedImage} alt="Full screen" style={{ width: '100%', height: 'auto' }} />
                    )}
                  </DialogContent>
                </Dialog>
              </div>

              {
                responseData || tempEditableData?.id? <div>
                  {
                    ['supplier_id', 'name', 'telephone', 'email', 'website'].map((field) => (
                      <div key={field} style={{ marginBottom: '10px', marginTop: '10px' }}>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                          {capitalizeField(field)}
                        </Typography>
                        {field === 'supplier_id' ? (
                          <Autocomplete
                          options={suppliers}
                          value={tempEditableData.supplier_id ? suppliers.find((supplier) => supplier.supplier_id === tempEditableData.supplier_id) : null}
                          getOptionLabel={getSupplierOptionLabel}
                          renderOption={(props, option) => (
                            <li {...props} key={option.supplier_id}>
                              <span>{option.supplier_id}</span>
                              <span style={{ marginLeft: '8px' }}>- {option.name}</span>
                            </li>
                          )}
                          filterOptions={(options, state) => filterOptions(options, state, true)}
                          onChange={(event, newValue) => {
                            handleFieldChange('supplier_id', newValue ? newValue.supplier_id : '');
                            validateField('supplier_id', newValue ? newValue.supplier_id : '');
                            setIsSaved(false); // Reset isSaved on change
                          }}
                          onFocus={handleFocusEvent}
                          onBlur={(e) => validateField('supplier_id', e.target.value)}
                          isOptionEqualToValue={(option, value) => option.supplier_id === value?.supplier_id}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth onKeyDown={handleKeyDown} error={!!validationErrors.supplier_id} helperText={validationErrors.supplier_id} />
                          )}
                        />
                        ) : (
                          <TextField
                            disabled={field === "name" && (tempEditableData[field] || '') !== '' && supplierNameDisabled}
                            value={tempEditableData[field] || ''}
                            onChange={(e) => handleFieldChange(field, e.target.value)}
                            onBlur={(e) => validateField(field, e.target.value)}
                            fullWidth
                            error={!!validationErrors[field]}
                            helperText={validationErrors[field]}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    disabled={field === "name" && (tempEditableData[field] || '') !== ''}
                                    aria-label="clear field"
                                    tabIndex={-1}
                                    onClick={() => {
                                      handleFieldChange(field, '');
                                      validateField(field, '');

                                    }}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </div>
                    ))
                  }
                  <Box display="flex" flexDirection="column" alignItems="inherit" mt={2} gap={2}>
                    <Tooltip title="Save changes made to this document" placement="right">
                      <span>
                        <Button
                          variant="outlined"
                          color="primary"
                          disabled={(tempEditableData?.id && !isEdited) || !allFieldsFilled()}
                          onClick={handleSave}
                          startIcon={<SaveIcon />}
                          style={{ padding: '10px 20px', width: '100%' }}
                        >
                          {tempEditableData?.id? 'Update': 'Create'}
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                </div>: null
              }
            </>
          )}
        </CardContent>
      </Card>

      {/* Status Snackbar */}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={!!statusMessage.message}
        autoHideDuration={6000}
        onClose={() => setStatusMessage({ type: '', message: '' })}
      >
        <Alert onClose={() => setStatusMessage({ type: '', message: '' })} severity={statusMessage.type} sx={{ width: '100%' }}>
          {statusMessage.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SupplierExtractedFields;