// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import ImageUpload from './ImageUpload';
import ReviewExtractionPage from './ReviewExtractionPage';
import LoginPage from './LoginPage';
import ProtectedRoute from './ProtectedRoute';
import { AuthProvider, useAuth } from './AuthContext';
import './App.css';
import logo from './maxresdefault.jpg'; // Make sure to import your logo image
import ReviewExportPage from './ReviewExportPage';
import ReviewSupplierPage from './ReviewSupplierPage';

function Header() {
  const { isAuthenticated, userRole, logout } = useAuth();

  return (
    <header className="App-header">
      <div className="logo-title">
        <img src={logo} className="logo" alt="Logo" />
        <h1 className="title">HaloVault</h1>
      </div>
      <nav>
        {!isAuthenticated ? (
          <Link to="/login">Login</Link>
        ) : (
          <>
            {(userRole === 'upload' || userRole === 'admin') && <Link to="/upload">Upload</Link>}
            {(userRole === 'review' || userRole === 'admin') && <Link to="/review">Review Extractions</Link>}
            {(userRole === 'review' || userRole === 'admin') && <Link to="/export">Export Docs</Link>}
            {(userRole === 'review' || userRole === 'admin') && <Link to="/supplier">Suppliers</Link>}
            <button onClick={logout}>Logout</button>
          </>
        )}
      </nav>
    </header>
  );
}

function AppRoutes() {
  const { isAuthenticated, userRole } = useAuth();

  // Determine the appropriate route based on user role
  const getRedirectPath = () => {
    if (userRole === 'upload') {
      return '/upload';
    } else if (userRole === 'review' || userRole === 'admin') {
      return '/review';
    } else if (userRole === 'review' || userRole === 'admin') {
      return '/supplier';
    } else {
      return '/login';
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={isAuthenticated ? <Navigate to={getRedirectPath()} /> : <Navigate to="/login" />}
      />
      <Route
        path="/upload"
        element={
          <ProtectedRoute requiredRole="upload">
            <ImageUpload />
          </ProtectedRoute>
        }
      />
      <Route
        path="/review"
        element={
          <ProtectedRoute requiredRole="review">
            <ReviewExtractionPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/export"
        element={
          <ProtectedRoute requiredRole="review">
            <ReviewExportPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/supplier"
        element={
          <ProtectedRoute requiredRole="review">
            <ReviewSupplierPage />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Header />
          <main>
            <AppRoutes />
          </main>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;