import React, { useState, useEffect } from 'react';
import {
  Typography,
  Card,
  CardContent,
  TextField,
  Tooltip,
  Button,
  Autocomplete,
  FormControlLabel,
  Box,
  Checkbox,
  IconButton,
  InputAdornment,
  styled,
  CircularProgress,
  Alert,
  Snackbar
} from '@mui/material';
import {
  Save as SaveIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Clear as ClearIcon
} from '@mui/icons-material';

// Styled Checkbox
const StyledCheckbox = styled((props) => <Checkbox {...props} />)({
  '&.Mui-checked': {
    color: '#3f51b5',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 28,
  },
});

// Date Validation Function
const validateDate = (dateString) => {
  const regexp = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  if (!regexp.test(dateString)) return false;

  const [day, month, year] = dateString.match(regexp).slice(1);
  const date = new Date(`${year}-${month}-${day}`);

  return (
    date.getDate() === parseInt(day, 10) &&
    date.getMonth() + 1 === parseInt(month, 10) &&
    date.getFullYear() === parseInt(year, 10)
  );
};

// Date Input Formatter
const formatInputDate = (value) => {
  const cleaned = value.replace(/[^0-9]/g, '');
  const match = cleaned.match(/(\d{1,2})(\d{0,2})(\d{0,4})/);
  if (match) {
    const part1 = match[1];
    const part2 = match[2] ? '/' + match[2] : '';
    const part3 = match[3] ? '/' + match[3] : '';
    return `${part1}${part2}${part3}`;
  }
  return value;
};

// Extracted Fields Component
const ExtractedFields = ({
  tempEditableData,
  sites,
  suppliers,
  nominalCodes,
  handleFieldChange,
  validationErrors,
  setValidationErrors,
  dateInput,
  setDateInput,
  isEdited,
  handleSaveChanges,
  handleDialogOpen,
  getLocationOptionLabel,
  getSupplierOptionLabel,
  getNominalCodeOptionLabel,
  refetchData,
  imageName,
  advanceToNextDocument,
  setPolygonProperty
}) => {
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState({ type: '', message: '' });
  const [topFilteredOption, setTopFilteredOption] = useState(null);
  const [filterValue, setFilterValue] = useState(null);

  const setFieldError = (field, error) => {
    setValidationErrors((prev) => ({ ...prev, [field]: error }));
  };

  const clearFieldError = (field) => {
    setValidationErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[field];
      return newErrors;
    });
  };

  // Validate Field
  const validateField = (field, value) => {
    if (['location_id', 'supplier_id', 'nominal_code_id', 'invoice_date', 'invoice_number', 'order_number', 'net', 'vat', 'total'].includes(field)) {
      if (!value && value !== 0) {
        setFieldError(field, 'This field is required');
      } else if (field === 'invoice_date' && !validateDate(value)) {
        setFieldError(field, 'Invalid date format. Use dd/mm/yyyy');
      } else {
        clearFieldError(field);
      }
    }
  };

  // Handle Field Blur
  const onFieldBlur = (field, value) => {
    if (field === 'net' || field === 'vat') {
      const formattedValue = value === '' ? '0.00' : parseFloat(value).toFixed(2);
      handleFieldChange(field, formattedValue);
    }
    validateField(field, value);
  };

  // Handle Date Change
  const handleDateChange = (e) => {
    const formattedDate = formatInputDate(e.target.value);
    setDateInput(formattedDate);
    handleFieldChange('invoice_date', formattedDate);
  };

  // Capitalize Field
  const capitalizeField = (field) => {
    if (field === 'vat') {
      return 'VAT';
    }
    return field
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleFocusEvent = (property) => {
    if (property) {
      setPolygonProperty(property);
    }
    setTopFilteredOption(null);
    setFilterValue(null);
  };
  
  // Filter Options
  const filterOptions = (options, state, isSupplier) => {
    const inputValue = state.inputValue.toLowerCase();
    setFilterValue(inputValue);
    if (isSupplier) {
      options = options.filter(
        (option) =>
          option.supplier_id?.toLowerCase().includes(inputValue) ||
          option.name?.toLowerCase().includes(inputValue)
      );
      if (options?.length > 0) {
        setTopFilteredOption(options[0]);
      }
      return options;
    }

    const startsWithOptions = options.filter(
      (option) =>
        option.location_id?.toString().toLowerCase().startsWith(inputValue) ||
        option.name?.toLowerCase().startsWith(inputValue)
    );

    if (startsWithOptions.length > 0) {
      setTopFilteredOption(startsWithOptions[0]);
      return startsWithOptions;
    }

    options = options.filter(
      (option) =>
        option.location_id?.toString().toLowerCase().includes(inputValue) ||
        option.name?.toLowerCase().includes(inputValue)
    );
    
    if (options?.length > 0) {
      setTopFilteredOption(options[0]);
    }

    return options
  };

  // Filter Nominal Code Options
  const filterNominalCodeOptions = (options, state) => {
    const inputValue = state.inputValue.toLowerCase();
    setFilterValue(inputValue);
    const startsWithOptions = options.filter(
      (option) =>
        option.nominal_code_id?.toLowerCase().startsWith(inputValue) ||
        option.name?.toLowerCase().startsWith(inputValue)
    );

    if (startsWithOptions.length > 0) {
      setTopFilteredOption(startsWithOptions[0]);
      return startsWithOptions;
    }

    options = options.filter(
      (option) =>
        option.nominal_code_id?.toLowerCase().includes(inputValue) ||
        option.name?.toLowerCase().includes(inputValue)
    );

    if (options?.length > 0) {
      setTopFilteredOption(options[0]);
    }

    return options;
  };


  // Check if all fields are filled
  const allFieldsFilled = () => {
    const requiredFields = [
      'location_id',
      'supplier_id',
      'nominal_code_id',
      'invoice_date',
      'invoice_number',
      'order_number',
      'net',
      'vat',
      'total',
    ];

    for (const field of requiredFields) {
      if (!tempEditableData[field] && tempEditableData[field] !== 0) {
        return false;
      }
    }

    return Object.keys(validationErrors).length === 0;
  };

  useEffect(() => {
    const requiredFields = [
      'location_id',
      'supplier_id',
      'nominal_code_id',
      'invoice_date',
      'invoice_number',
      'order_number',
      'net',
      'vat',
      'total',
    ];

    requiredFields.forEach((field) => {
      validateField(field, tempEditableData[field]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    if (allFieldsFilled()) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationErrors]);

  const handleSave = async () => {
    setLoading(true);
    try {
      await handleSaveChanges();
      setStatusMessage({ type: 'success', message: 'Changes saved successfully.' });
      setIsSaved(true);
      // await advanceToNextDocument();
    } catch (error) {
      setStatusMessage({ type: 'error', message: 'Failed to save changes. Please try again.' });
    } finally {
      setLoading(false);
      refetchData(); // Optional: refetch data after save, if applicable
    }
  };


  const handleKeyDown = (event) => {
    if (event.key === 'Tab' && filterValue) {
    
      if (topFilteredOption?.supplier_id) {
        tempEditableData.supplier_id = topFilteredOption.supplier_id;

      } else if (topFilteredOption?.location_id) {
        tempEditableData.location_id = topFilteredOption.location_id;

      } else if (topFilteredOption?.nominal_code_id) {
        tempEditableData.nominal_code_id = topFilteredOption.nominal_code_id;

      }
      
      //event.preventDefault(); // Prevent default Tab behavior
    }
  };

  // Render
  return ( 
    <> 
      <Card> 
        <CardContent> 
          <Tooltip title={"Job Number Not Editable"}>
            <span>
              <Typography variant="h6" sx={{ fontWeight: "bold" }} display="inline"> 
                Job Number: 
              </Typography>
              <Typography variant="h6" display="inline" gutterBottom>
                &nbsp;
              </Typography>
              <Typography variant="h6" display="inline" gutterBottom> 
                {tempEditableData.job_id.toUpperCase()}
              </Typography>
            </span>
          </Tooltip>
          <Typography variant="h6" display="block" gutterBottom sx={{ fontStyle: 'italic', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}> 
            {imageName} {/* Show the image name instead of "Extracted Fields" */}
          </Typography>
          
          {loading ? (
            <Box display="flex" justifyContent="center" my={3}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {['location_id', 'supplier_id', 'nominal_code_id', 'invoice_date', 'invoice_number', 'order_number', 'net', 'vat', 'total'].map((field) => (
                <div key={field} style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <Typography variant="subtitle2" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                    {capitalizeField(field)}
                  </Typography>
                  {field === 'location_id' ? (
                    <Autocomplete
                      disabled={tempEditableData.doc_status_id === 10}
                      options={sites}
                      value={tempEditableData.location_id ? sites.find((location) => location.location_id === tempEditableData.location_id) : null}
                      getOptionLabel={getLocationOptionLabel}
                      renderOption={(props, option) => (
                        <li {...props} key={option.location_id}>
                          <span>{option.location_id}</span>
                          <span style={{ marginLeft: '8px' }}>- {option.name}</span>
                        </li>
                      )}
                      filterOptions={(options, state) => filterOptions(options, state, false)}
                      onChange={(event, newValue) => {
                        handleFieldChange('location_id', newValue ? newValue.location_id : '');
                        validateField('location_id', newValue ? newValue.location_id : '');
                        setIsSaved(false); // Reset isSaved on change
                      }}
                      onBlur={(e) => validateField('location_id', e.target.value)}
                      onFocus={() => handleFocusEvent('customer_delivery_postcode')}
                      isOptionEqualToValue={(option, value) => option.location_id === value?.location_id}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth onKeyDown={handleKeyDown} error={!!validationErrors.location_id} helperText={validationErrors.location_id} />
                      )}
                    />
                  ) : field === 'supplier_id' ? (
                    <Autocomplete
                      disabled={tempEditableData.doc_status_id === 10}
                      options={suppliers}
                      value={tempEditableData.supplier_id ? suppliers.find((supplier) => supplier.supplier_id === tempEditableData.supplier_id) : null}
                      getOptionLabel={getSupplierOptionLabel}
                      renderOption={(props, option) => (
                        <li {...props} key={option.supplier_id}>
                          <span>{option.supplier_id}</span>
                          <span style={{ marginLeft: '8px' }}>- {option.name}</span>
                        </li>
                      )}
                      filterOptions={(options, state) => filterOptions(options, state, true)}
                      onChange={(event, newValue) => {
                        handleFieldChange('supplier_id', newValue ? newValue.supplier_id : '');
                        validateField('supplier_id', newValue ? newValue.supplier_id : '');
                        setIsSaved(false); // Reset isSaved on change
                      }}
                      onBlur={(e) => validateField('supplier_id', e.target.value)}
                      onFocus={() => handleFocusEvent('supplier_name')}
                      isOptionEqualToValue={(option, value) => option.supplier_id === value?.supplier_id}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth onKeyDown={handleKeyDown} error={!!validationErrors.supplier_id} helperText={validationErrors.supplier_id} />
                      )}
                    />
                  ) : field === 'nominal_code_id' ? (
                    <Autocomplete
                      disabled={tempEditableData.doc_status_id === 10}
                      options={nominalCodes}
                      value={tempEditableData.nominal_code_id ? nominalCodes.find((nominalCode) => nominalCode.nominal_code_id === tempEditableData.nominal_code_id) : null}
                      getOptionLabel={getNominalCodeOptionLabel}
                      renderOption={(props, option) => (
                        <li {...props} key={option.nominal_code_id}>
                          <span>{option.nominal_code_id}</span>
                          <span style={{ marginLeft: '8px' }}>- {option.name}</span>
                        </li>
                      )}
                      filterOptions={filterNominalCodeOptions}
                      onChange={(event, newValue) => {
                        handleFieldChange('nominal_code_id', newValue ? newValue.nominal_code_id : null); // Changed to null if no value
                        validateField('nominal_code_id', newValue ? newValue.nominal_code_id : null); // Changed to null if no value
                      }}
                      onBlur={(e) => validateField('nominal_code_id', e.target.value)}
                      onFocus={() => handleFocusEvent('nominal_code')}
                      isOptionEqualToValue={(option, value) => option.nominal_code_id === value?.nominal_code_id}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth onKeyDown={handleKeyDown} error={!!validationErrors.nominal_code_id} helperText={validationErrors.nominal_code_id} />
                      )}
                    />

                  ) : field === 'net' || field === 'vat' ? (
                    <TextField
                      disabled={tempEditableData.doc_status_id === 10}
                      value={tempEditableData[field] || ''}
                      onChange={(e) => handleFieldChange(field, e.target.value)}
                      fullWidth
                      InputProps={{
                        startAdornment: <Typography variant="subtitle2" style={{ marginRight: '8px' }}>£</Typography>,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={tempEditableData.doc_status_id === 10}
                              aria-label="clear field"
                              tabIndex={-1}
                              onClick={() => {
                                handleFieldChange(field, '');
                                onFieldBlur(field, '');

                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={!!validationErrors[field]}
                      helperText={validationErrors[field]}
                      onBlur={(e) => onFieldBlur(field, e.target.value)}
                      onFocus={() => setPolygonProperty(field)}
                    />
                  ) : field === 'total' ? (
                    <TextField
                      disabled={tempEditableData.doc_status_id === 10}
                      value={`£${tempEditableData.credit_note ? Math.abs(parseFloat(tempEditableData.total)).toFixed(2) : parseFloat(tempEditableData.total).toFixed(2)}`}
                      fullWidth
                      InputProps={{ readOnly: true }}
                      style={tempEditableData.credit_note ? { backgroundColor: '#ffebeb', color: 'red' } : { backgroundColor: '#f0f0f0' }}
                      error={!!validationErrors.total}
                      helperText={validationErrors.total}
                      onFocus={() => setPolygonProperty('total')}
                    />
                  ) : field === 'invoice_date' ? (
                    <TextField
                      disabled={tempEditableData.doc_status_id === 10}
                      value={dateInput}
                      onChange={handleDateChange}
                      fullWidth
                      error={!!validationErrors.invoice_date}
                      helperText={validationErrors.invoice_date}
                      onBlur={(e) => onFieldBlur('invoice_date', dateInput)}
                      onFocus={() => setPolygonProperty('date_of_invoice')}
                    />
                  ) : (
                    <TextField
                      disabled={tempEditableData.doc_status_id === 10}
                      value={tempEditableData[field] || ''}
                      onChange={(e) => handleFieldChange(field, e.target.value)}
                      onFocus={() => setPolygonProperty(field)}
                      onBlur={(e) => validateField(field, e.target.value)}
                      fullWidth
                      error={!!validationErrors[field]}
                      helperText={validationErrors[field]}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={tempEditableData.doc_status_id === 10}
                              aria-label="clear field"
                              tabIndex={-1}
                              onClick={() => {
                                handleFieldChange(field, '');
                                validateField(field, '');

                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </div>
              ))}
              <div style={{ marginLeft: '-185px' }}>
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      disabled={tempEditableData.doc_status_id === 10}
                      checked={tempEditableData.credit_note || false}
                      onChange={(e) => {
                        handleFieldChange('credit_note', e.target.checked);
                        setIsSaved(false); // Reset isSaved on change
                      }}
                    />
                  }
                  label={<Typography variant="subtitle1" style={{ color: '#3f51b5' }}>Credit Note</Typography>}
                />
              </div>
              {
              tempEditableData.doc_status_id !== 10?
                <Box display="flex" flexDirection="column" alignItems="inherit" mt={2} gap={2}>
                  {/* Save Button */}
                  <Tooltip title="Save changes made to this document" placement="right">
                    <span>
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={!isEdited || !allFieldsFilled()}
                        onClick={handleSave}
                        startIcon={<SaveIcon />}
                        style={{ padding: '10px 20px', width: '100%' }}
                      >
                        Save
                      </Button>
                    </span>
                  </Tooltip>
                </Box>: null
              }
            </>
          )}
        </CardContent>
      </Card>

      {/* Status Snackbar */}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={!!statusMessage.message}
        autoHideDuration={6000}
        onClose={() => setStatusMessage({ type: '', message: '' })}
      >
        <Alert onClose={() => setStatusMessage({ type: '', message: '' })} severity={statusMessage.type} sx={{ width: '100%' }}>
          {statusMessage.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ExtractedFields;