import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import heic2any from 'heic2any';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  IconButton,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  List, ListItem, ListItemText
} from '@mui/material';
import Box from '@mui/material/Box';

import { Delete as DeleteIcon } from '@mui/icons-material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import { isMobile } from 'react-device-detect';


import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

const DropzoneContainer = styled('div')({
  border: '2px dashed #ccc',
  borderRadius: '8px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'border-color 0.3s',
  '&:hover': {
    borderColor: '#3f51b5',
  },
});

const Thumbnail = styled('div')({
  position: 'relative',
  // margin: '10px',
  textAlign: 'center',
  '& img': {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  '& .pdf-icon': {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  '& .loadingOverlay': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
  }
});

const ThumbnailPDF = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  margin: '10px',
  textAlign: 'center',
  '& .pdf-icon': {
    width: '100px',
    height: '100px',
    backgroundColor: '#e0e0e0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    fontSize: '1.5rem',
    color: '#3f51b5',
  },
  '& .icon-button': {
    position: 'absolute', 
    top: '-10px', 
    right: '-10px', 
    color: 'red',
  }
});

const ImageUpload = () => {
  const defaultSearchedJobs = {id: "null", name: "Select Job"};

  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [supplierId, setSupplierId] = useState(null);
  const [analysisError, setAnalysisError] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [locations, setLocations] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [searchingJob, setSearchingJob] = useState(false);
  const [jobNumber, setJobNumber] = useState('');
  const [jobNumberError, setJobNumberError] = useState('');
  const [searchedJobs, setSearchedJobs] = useState([defaultSearchedJobs]);
  const [selectedJobId, setSelectedJobId] = useState(defaultSearchedJobs.id);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  useEffect(() => {
    localStorage.removeItem('uploads');
    const storedData = JSON.parse(localStorage.getItem('uploads')) || [];
    setImages(storedData);

    const fetchLocationsAndSuppliers = async () => {
      try {
        const [locationsRes, suppliersRes] = await Promise.all([
          axios.get(`${API_BASE_URL}/api/v1/location`, {
            headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
          }),
          axios.get(`${API_BASE_URL}/api/v1/supplier`, {
            headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
          }),
        ]);

        setLocations(locationsRes.data.data);
        setSuppliers(suppliersRes.data.data);
      } catch (err) {
        console.error('Failed to fetch locations or suppliers', err);
      }
    };

    fetchLocationsAndSuppliers();
  }, []);


  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/heic': ['.heic'],
      'application/pdf': ['.pdf'],
    },
    onDrop: async (acceptedFiles) => {
      setResponseData(null);
      // console.log("Accepted Files ===>", acceptedFiles);
      
      const convertedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          if (file.type === 'image/heic' || file.name.endsWith('.heic')) {
            try {
              setSuccess(true);
              setResponseMessage(`Converting HEIC to JPEG image.`);
              const blob = await heic2any({ blob: file, toType: 'image/jpeg' });
              setSuccess(false);
              return new File([blob], file.name.replace(/\.heic$/i, '.jpg'), {type: 'image/jpeg'});
            
            } catch (error) {
              setSuccess(false);
              console.error('Error converting HEIC to JPEG:', error);
              setError(`Error converting HEIC to JPEG: ${JSON.stringify(error)}`)
              return null;
              
            }
          }
          setSuccess(false);
          return file;
        })
      );

      const newImages = convertedFiles.filter(i => i).map((file) => ({
        file,
        preview: URL.createObjectURL(file),
        isLoading: true,
        error: null,
      }));

      setImages((prevImages) => [...prevImages, ...newImages]);

      for (const newImage of newImages) {
        // if (newImage.file.type !== 'application/pdf') {
        const validationResponse = await validateDocImage(newImage);
        newImage.isLoading = false;
        
        if (validationResponse) {
          newImage.error = validationResponse;
          setImages((prevImages) => [...prevImages]);
        }
      }

      // Once all new images are processed, check if all images are done loading
      checkAllImagesLoaded();
    },
  });


  const validateDocImage = async (imageSrc) => {
    const formData = new FormData();
    formData.append('image', imageSrc.file);
    
    // Log form data for debugging
    // for (const pair of formData.entries()) {
    //   console.log(`${pair[0]}: ${pair[1]}`);
    // }

    setAnalysisError('');
    try {
      
      const response = await axios.post(`${API_BASE_URL}/api/v1/doc/validate`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      });

      if (response.status === 200) {
        const {invoice_or_credit_note, rotated, in_view, blurry, good_brightness, supplier_id} = response.data.data;
        return {invoice_or_credit_note, rotated, in_view, blurry, good_brightness, supplier_id};

      }

    } catch (err) {
      setAnalysisError(`Failed to validate pdf/image ${imageSrc.file.name}. Please try again.`);
      setImages((prevImages) => prevImages.filter((image) => image !== imageSrc));
      return null;
    
    }
  };


  const checkAllImagesLoaded = useCallback(() => {
    const allLoaded = images.every((image) => {
      if (image.isLoading) {
        return false;
      }
      
      if (image?.error === null) {
        return false;
      }
      
      const error = image?.error;
      if (error?.invoice_or_credit_note === true && error?.rotated === false && error?.in_view === true && error?.blurry === false && error?.good_brightness === true) {
        if (error?.supplier_id !== null) {
          setSupplierId(error?.supplier_id);
        }

        return true;
      }
      
    });
    setAllImagesLoaded(allLoaded);

  }, [images]);


  useEffect(() => {
    if (images.length > 0) {
      checkAllImagesLoaded();
    }
  }, [images, checkAllImagesLoaded]);


  const findLocationName = (id) => {
    const location = locations.find((loc) => loc.location_id === id);
    return location ? location.name : `Unknown (ID: ${id})`;
  };


  const findSupplierName = (id) => {
    const supplier = suppliers.find((sup) => sup.supplier_id === id);
    return supplier ? supplier.name : `Unknown (ID: ${id})`;
  };


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents default form submission behavior

    const formData = new FormData();
    for (let index=0; index <images.length; index++) {
      formData.append(`image${index}`, images[index].file);
    }
    
    // Include job_id in the formData
    formData.append('supplier_id', supplierId);
    formData.append('job_id', selectedJobId);
    
    // Log form data for debugging
    for (const pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    setUploading(true);
    setAnalysisError('');
    setResponseData(null);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/v1/doc`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      });

      if (response.status === 200) {
        setSuccess(true);
        setResponseMessage(response.data.message || 'Images processed successfully!');
        setResponseData(response.data);

        const storedData = JSON.parse(localStorage.getItem('uploads')) || [];
        const newUploads = images.map((image, index) => ({
          preview: image.preview,
          data: response.data,
        }));

        const updatedData = [...storedData, ...newUploads];
        localStorage.setItem('uploads', JSON.stringify(updatedData));

        setImages([]);
        setSelectedImage(null);
        // clearJobNumber(); // Clear the job number after successful submission
      }

    } catch (err) {
      if (err.response && err.response.status === 422) {
        setError('One or more images are too blurry.');
        if (err.response.data && err.response.data.data) {
          const blurryFiles = err.response.data.data.map((file) => file.filename).join(', ');
          setAnalysisError(`The following images are too blurry: ${blurryFiles}`);
        }
      } else {
        if (err?.response?.data?.error.includes("due to lack of permission")) {
          setError(`Failed to process image(s). No write permission to job pack ${searchedJobs.filter(job => job.id === selectedJobId)[0]?.name}`);
        
        } else {
          setError(`Failed to process image(s). ${err?.response?.data?.error}`);
        
        }
        
        console.error(err);
      }

    } finally {
      setUploading(false);

    }
  };

  const handleJobNumberChange = (event) => {
    setResponseData(null);
    const number = event.target.value;
    setJobNumber(number);
    setJobNumberError('');
  };

  const clearJobNumber = () => {
    setJobNumber('');
    setJobNumberError('');
    setSearchedJobs([defaultSearchedJobs]);
    setSelectedJobId(defaultSearchedJobs.id);
  };

  const renderReadableResponseData = (data) => (
    <div
      style={{
        marginTop: '20px',
        backgroundColor: '#f0f0f0',
        padding: '10px',
        borderRadius: '8px',
      }}
    >
      <Typography variant="h6">Last Document Uploaded:</Typography>
      <Typography variant="body1">
        <strong>Job Number:</strong> {data.job_id || ''}
      </Typography>
      <Typography variant="body1">
        <strong>Location:</strong> {findLocationName(data.location_id || '')}
      </Typography>
      <Typography variant="body1">
        <strong>Supplier:</strong> {findSupplierName(data.supplier_id || '')}
      </Typography>
      <Typography variant="body1">
        <strong>Invoice Date:</strong>{' '}
        {data.invoice_date ? new Date(data.invoice_date).toLocaleDateString() : ''}
      </Typography>
      <Typography variant="body1">
        <strong>Invoice Number:</strong> {data.invoice_number || ''}
      </Typography>
      <Typography variant="body1">
        <strong>Order Number:</strong> {data.order_number || ''}
      </Typography>
      <Typography variant="body1">
        <strong>Net:</strong> {data.net || ''}
      </Typography>
      <Typography variant="body1">
        <strong>VAT:</strong> {data.vat || ''}
      </Typography>
      <Typography variant="body1">
        <strong>Total:</strong> {data.total || ''}
      </Typography>
      <Typography variant="body1">
        <strong>Credit Note:</strong> {data.credit_note ? 'Yes' : 'No'}
      </Typography>
    </div>
  );

  const handleImageClick = (image) => {
    console.log('Setting image', image);
    setSelectedImage(image);
  };

  const handleFileClick = (file) => {
    console.log('Setting file', file);
    setSelectedFile(file);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setSelectedFile(null);
  };


  const handleDeleteImage = (imageToDelete) => {
    const updatedImages = images.filter((image) => image !== imageToDelete);
    setImages(updatedImages);
    localStorage.setItem('uploads', JSON.stringify(updatedImages));
    // setAllImagesLoaded(updatedImages.length !== 0);
    checkAllImagesLoaded();
  };


  const handleJobSearch = async () => {
    try {
      if (jobNumber.trim().length === 0) {
        return
      }

      setSearchedJobs([defaultSearchedJobs]);
      setSelectedJobId(defaultSearchedJobs.id);
      setSearchingJob(true);
      const response = await axios.get(`${API_BASE_URL}/api/v1/job?job_id=${jobNumber.trim()}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      });
      
      if (response.status === 200) {
        setSearchingJob(false);
        const allSearchedJobs = response.data.data.result.map((job) => {return {"id": job.id, "name": job.name}});
        if (allSearchedJobs.length > 0) {
          setSearchedJobs(allSearchedJobs);
          setSelectedJobId(allSearchedJobs[0].id);
        
        } else {
          setJobNumberError("No Job Found");
        
        }
      }

    } catch (err) {
      setSearchingJob(false);
      console.error(JSON.stringify(err.response));
      setError(`Failed to search job ${jobNumber.trim()}.}`);

    } finally {
      setUploading(false);

    }
  };


  const handleJobSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleJobSearch();
    }
  }

  return (
    <div style={{ padding: '20px' }}> 
      <form onSubmit={handleSubmit}>
        <div style={{ marginTop: '20px', position: 'relative' }}>
          <Box display="flex" alignItems="center" gap={2}>
            <TextField
              variant="outlined"
              label="Job Number"
              fullWidth
              placeholder="Search..."
              error={jobNumberError !== ""}
              helperText={jobNumberError}
              value={jobNumber}
              onChange={handleJobNumberChange}
              onKeyDown={handleJobSearchKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchingJob ? (
                      <CircularProgress size={20} />
                    ) : (

                      jobNumber !== null && jobNumber !== ""?   <Box
                      sx={{
                        width: 32,          // Circle's width
                        height: 32,         // Circle's height
                        borderRadius: '50%', // Makes the Box a circle
                        backgroundColor: '#0073e6', // Blue color
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={handleJobSearch}
                    >
                      <SearchIcon style={{ color: '#fff' }} /> {/* White color for the icon */}
                    </Box>:
                      <SearchIcon onClick={handleJobSearch} style={{ cursor: 'pointer' }}  />
                    )}
                    
                  </InputAdornment>
                ),
              }}
            />
          </Box> 
        </div>
        <div style={{ marginTop: '20px', position: 'relative' }}>
          <Box display="flex" alignItems="center" gap={2}>
            <Select
                  fullWidth
                  value={selectedJobId}
                  onChange={(e) => setSelectedJobId(e.target.value)}
                >
                  {searchedJobs.map(job => (
                    <MenuItem value={job.id}>
                      {job.name}
                    </MenuItem>
                  ))}
            </Select>
          </Box>
        </div>
        <div style={{ marginTop: '20px', position: 'relative' }}>
          <DropzoneContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <Typography variant="body1">
              {isMobile ? 'Take a picture or upload a PDF' : "Drag 'n drop or click to Upload a File"}
            </Typography>
          </DropzoneContainer>

          {analysisError && (
            <Alert severity="error" style={{ marginTop: '20px' }}>
              {analysisError}
            </Alert>
          )}

          <Grid container spacing={2} style={{ marginTop: '20px' }}>
            {images.map((image) => (
              <Grid item key={image.file.name} xs={12} sm={6} md={4} lg={3}>
                {image.file.type === 'application/pdf' ? (
                  <Thumbnail>
                    <div className="pdf-icon" onClick={() => handleFileClick(image.preview)}>
                      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                        <Viewer fileUrl={image.preview} />
                      </Worker>
                    </div>
                    {
                      image.isLoading && (
                      <div className="loadingOverlay">
                        <CircularProgress />
                      </div>
                      )
                    }
                    
                    <IconButton
                      style={{ position: 'absolute', top: 0, right: 0, color: 'red' }}
                      onClick={() => handleDeleteImage(image)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <List>
                      {
                        image?.error && image?.error?.invoice_or_credit_note === false && 
                        <ListItem>
                          <ListItemText primary={ <Typography variant="body1" color="error">Should be an invoice/credit note.</Typography> }/>
                        </ListItem>
                      }
                      {
                        image?.error && image?.error?.in_view === false && 
                        <ListItem>
                          <ListItemText primary={ <Typography variant="body1" color="error">Should be in view and cover more than 90% of screen.</Typography> }/>
                        </ListItem>
                      }
                      {    
                        image?.error && image?.error?.blurry === true &&
                        <ListItem>
                          <ListItemText primary={ <Typography variant="body1" color="error">Should not be blurry.</Typography> }/>
                        </ListItem>
                      }
                      {
                        image?.error && image?.error?.rotated === true && 
                        <ListItem>
                          <ListItemText primary={ <Typography variant="body1" color="error">Should not be rotated.</Typography> }/>
                        </ListItem>
                      }
                      {    
                        image?.error && image?.error?.good_brightness === false &&
                        <ListItem>
                          <ListItemText primary={ <Typography variant="body1" color="error">Should have balanced brightness.</Typography> }/>
                        </ListItem>
                      }
                    </List>
                  </Thumbnail>
                ) : (
                  <Thumbnail>
                    <div className='pdf-icon'>
                      <img src={image.preview} alt="preview" onClick={() => handleImageClick(image.preview)} />
                    </div>
                    {image.isLoading && (
                      <div className="loadingOverlay">
                        <CircularProgress />
                      </div>
                    )}
                    <IconButton
                      style={{ position: 'absolute', top: 0, right: 0, color: 'red' }}
                      onClick={() => handleDeleteImage(image)}
                    >
                      <DeleteIcon />
                    </IconButton>
                      <List>
                        {
                          image?.error && image?.error?.invoice_or_credit_note === false && 
                          <ListItem>
                            <ListItemText primary={ <Typography variant="body1" color="error">Should be an invoice/credit note.</Typography> }/>
                          </ListItem>
                        }
                        {
                          image?.error && image?.error?.in_view === false && 
                          <ListItem>
                            <ListItemText primary={ <Typography variant="body1" color="error">Should be in view and cover more than 90% of screen.</Typography> }/>
                          </ListItem>
                        }
                        {    
                          image?.error && image?.error?.blurry === true &&
                          <ListItem>
                            <ListItemText primary={ <Typography variant="body1" color="error">Should not be blurry.</Typography> }/>
                          </ListItem>
                        }
                        {
                          image?.error && image?.error?.rotated === true && 
                          <ListItem>
                            <ListItemText primary={ <Typography variant="body1" color="error">Should not be rotated.</Typography> }/>
                          </ListItem>
                        }
                        {    
                          image?.error && image?.error?.good_brightness === false &&
                          <ListItem>
                            <ListItemText primary={ <Typography variant="body1" color="error">Should have balanced brightness.</Typography> }/>
                          </ListItem>
                        }
                      </List>
                  </Thumbnail>
                )}
              </Grid>
            ))}
          </Grid>
          <Button
            type="submit" // Add this to trigger form submission on Enter key press
            variant="contained"
            color="primary"
            disabled={uploading || selectedJobId === "null" || !allImagesLoaded || images.length === 0}
            style={{ marginTop: '20px' }}
          >
            {uploading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </div>
      </form>

      {responseData && responseData.data && renderReadableResponseData(responseData.data)}
      {success && (
        <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
          <Alert onClose={() => setSuccess(false)} severity="success">
            {responseMessage}
          </Alert>
        </Snackbar>
      )}
      {error && (
        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
          <Alert onClose={() => setError('')} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}

      <Dialog open={!!selectedImage} onClose={handleCloseModal} maxWidth="lg" fullWidth>
        <DialogContent style={{ position: 'relative' }}>
          <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          {selectedImage && (
            <img src={selectedImage} alt="Full screen" style={{ width: '100%', height: 'auto' }} />
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={!!selectedFile} onClose={handleCloseModal} maxWidth="lg" fullWidth>
        <DialogContent style={{ position: 'relative' }}>
          <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          {selectedFile && (
            <Worker style={{ width: '100%', height: 'auto' }} workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
              <Viewer fileUrl={selectedFile} />
            </Worker>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImageUpload;