import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CircularProgress, Container, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import ExportGridView from './ExportGridView';
import ExportPage from './ExportPage';
import { formatDate, revertDateFormat } from './dateUtils';
import './ReviewExportPage.css'; // Import the new CSS file

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

const ReviewExportPage = () => {
  const defaultSelectedDocStatuses = [1, 8, 9];
  const pageLimit = 10; // Set the limit per page for easy access
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [filteredDataList, setFilteredDataList] = useState([]);
  const [detailedData, setDetailedData] = useState(null);
  const [editableData, setEditableData] = useState(null);
  const [tempEditableData, setTempEditableData] = useState(null);
  const [locations, setLocations] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [nominalCodes, setNominalCodes] = useState([]);
  const [docStatuses, setDocStatuses] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [sortCriteria, setSortCriteria] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isEdited, setIsEdited] = useState(false);
  const [zoomEnabled, setZoomEnabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0); // Add totalDocuments state
  const [allowedDocStatuses, setAllowedDocStatuses] = useState(['Approved', 'Export In Progress', 'Export Failed', 'Duplicate Export', 'Exported']);
  const [selectedDocStatuses, setSelectedDocStatuses] = useState(defaultSelectedDocStatuses); // Set to 1 by default
  const [notification, setNotification] = useState({ message: '', type: '', open: false }); // Add state for notifications

  const [statusMessage, setStatusMessage] = useState({ type: '', message: '' });
  const [showJobBox, setShowJobBox] = useState(false);
  const [jobId, setJobId] = useState('last');
  const [jobInProgress, setJobInProgress] = useState(false);
  const [processedDocs, setProcessedDocs] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [jobMessage, setJobMessage] = useState('');
  const [exportAllDocs, setExportAllDocs] = useState(false);
  const [exportSelectedDocs, setExportSelectedDocs] = useState(false);
  const [exportDocsCount, setExportDocsCount] = useState(0);
  const [sortedData, setSortedData] = useState([]);
  
  

  const transformWrapperRef = useRef(null);

  // Define handlePreviousImage function
  const handlePreviousImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    zoomOut();
    setZoomEnabled(false);
  }, []);

  // Define handleNextImage function
  const handleNextImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) => (prevIndex < detailedData.images.length - 1 ? prevIndex + 1 : prevIndex));
    zoomOut();
    setZoomEnabled(false);
  }, [detailedData]);


  // Use useCallback to define fetchData
  const fetchData = useCallback(async (page = 1) => {
    setLoading(true);
    try {
        // console.log("==================================Fetching data for==================", currentPage, page);
        // Construct query object dynamically
        const queryObject = {
            page,
            limit: pageLimit,
        };

        if (sortCriteria) {
            queryObject.sort = { [sortCriteria]: sortOrder };
        }

        const filter = {};
        if (selectedSupplier) {
            filter.supplier_id = selectedSupplier;
        }
        if (selectedLocation) {
            filter.location_id = selectedLocation;
        }
        
        if (selectedDocStatuses.length > 0) {
          filter.doc_status_id = { $in: selectedDocStatuses };
        
        } else if (docStatuses) {
          filter.doc_status_id = { $in: docStatuses.filter((d) => allowedDocStatuses.includes(d.name)).map((d) => d.doc_status_id) };
  
        }

        // Add filter to queryObject if there are filter conditions
        if (Object.keys(filter).length > 0) {
            queryObject.filter = filter;
        }

        const queryBase64 = await fetchBase64(queryObject);
        //console.log('Query base64:', queryBase64);

        const [
            uploadsResponse,
            locationsResponse,
            suppliersResponse,
            nominalCodesResponse,
            docStatusResponse,
        ] = await Promise.all([
            axios.get(`${API_BASE_URL}/api/v1/doc?query=${queryBase64}`, { headers: { Authorization: `Bearer ${AUTH_TOKEN}` } }),
            axios.get(`${API_BASE_URL}/api/v1/location`, { headers: { Authorization: `Bearer ${AUTH_TOKEN}` } }),
            axios.get(`${API_BASE_URL}/api/v1/supplier`, { headers: { Authorization: `Bearer ${AUTH_TOKEN}` } }),
            axios.get(`${API_BASE_URL}/api/v1/nominal-code`, { headers: { Authorization: `Bearer ${AUTH_TOKEN}` } }),
            axios.get(`${API_BASE_URL}/api/v1/doc/status`, { headers: { Authorization: `Bearer ${AUTH_TOKEN}` } }),
        ]);

        //console.log('Uploads response:', uploadsResponse.data);

        const formattedDocuments = uploadsResponse.data.data.documents.map(doc => ({
            ...doc,
            invoice_date: formatDate(doc.invoice_date),
            created_on: formatDate(doc.created_on), // Format "created_on" date
        }));
        //console.log('image name:', formattedDocuments[0].image_name)

        const totalDocs = uploadsResponse.data.data.total_documents || 0;
        const totalPages = Math.ceil(totalDocs / pageLimit);

        // Update state only once at the end of this function
        setDataList(formattedDocuments);
        setFilteredDataList(formattedDocuments); // Set filteredDataList based on fetched data
        setLocations(locationsResponse.data.data || []);
        setSuppliers(suppliersResponse.data.data || []);
        setNominalCodes(nominalCodesResponse.data.data || []);
        setDocStatuses(docStatusResponse.data.data.filter((status) => allowedDocStatuses.includes(status.name)) || []);
        setTotalDocuments(totalDocs);
        setTotalPages(totalPages);

        setLoading(false);
        return formattedDocuments;

    } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
    }
  }, [sortCriteria, sortOrder, selectedSupplier, selectedLocation, selectedDocStatuses, allowedDocStatuses, currentPage]);

  const fetchBase64 = async (queryObject) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/text/base64`,
        queryObject,
        {
          headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
        }
      );
      return response.data.data; // Ensure we're returning only the base64 string from the response
    } catch (error) {
      console.error('Error fetching base64:', error);
      throw error;
    }
  };

  useEffect(() => {
    // console.log("==================================Current Page change==================", currentPage);
    fetchData(currentPage); // Fetch data on component mount with the initial page
  }, [currentPage, fetchData]);

  const handleCardClick = async (doc) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/doc/${doc.id}`, {
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
      });
      const data = response.data.data;
      
      const formattedData = {
        ...data,
        invoice_date: formatDate(data.invoice_date), // Format the date to the desired format
        net: parseFloat(data.net).toFixed(2),        // Format net to 2 decimal places
        vat: parseFloat(data.vat).toFixed(2),        // Format vat to 2 decimal places
        total: parseFloat(data.total).toFixed(2),    // Format total to 2 decimal places
      };
      

      setDetailedData(formattedData);
      setEditableData(formattedData);
      setTempEditableData(formattedData);
      setCurrentImageIndex(0);
      //console.log('Detailed data:', formattedData);
      if (zoomEnabled) {
        zoomOut();
        setZoomEnabled(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching detailed data:', error);
    }
  };

  const getImageName = () => {
    const matchingDoc = filteredDataList.find(doc => doc.id === detailedData?.id);
    const fullImageName = matchingDoc?.images?.[0] || 'No Image';
    const imageNameParts = fullImageName.split('_');
    return imageNameParts.length > 1 ? imageNameParts[1] : fullImageName;
  };

  const handleFieldChange = (field, value) => {
    setTempEditableData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    if (field === 'net' || field === 'vat') {
      const net = parseFloat(field === 'net' ? value : tempEditableData.net) || 0;
      const vat = parseFloat(field === 'vat' ? value : tempEditableData.vat) || 0;
      setTempEditableData((prevData) => ({
        ...prevData,
        total: (net + vat).toFixed(2),
      }));
    }

    setIsEdited(true);
  };

  const handleFieldBlur = (field, value) => {
    const formattedValue = parseFloat(value).toFixed(2);
    setTempEditableData((prevData) => ({
      ...prevData,
      [field]: formattedValue,
    }));
  };

  const handleBackClick = () => {
    // console.log("==================================Handle back click==================", currentPage);
    setDetailedData(null);
    setEditableData(null);
    setTempEditableData(null);
    setIsEdited(false);
    fetchData(currentPage);
    // setCurrentPage(1); // Reset to page 1 when returning to GridView
  };

  const handleSupplierChange = (event) => {
    const selectedSupplier = event.target.value;
    setSelectedSupplier(selectedSupplier);
    filterData(selectedSupplier, selectedLocation);
  };

  const handleLocationChange = (event) => {
    const locationId = event.target.value;
    setSelectedLocation(locationId);
    filterData(selectedSupplier, locationId);
  };

  const handlePageChange = (newPage) => {
    // console.log("==================================Handle Page Change==================", newPage);
    setExportAllDocs(false);
    setExportSelectedDocs(false);
    setCurrentPage(newPage);
  };

  const handleDocStatusChange = (selectedStatuses) => {
    setSelectedDocStatuses(selectedStatuses);
    filterData(selectedSupplier, selectedLocation, selectedStatuses);
  };

  const handleClearFilters = () => {
    setSelectedSupplier('');
    setSelectedLocation('');
    setSelectedDocStatuses(defaultSelectedDocStatuses); // Reset to default
    setSortCriteria('');
    setSortOrder('asc');
    setFilteredDataList(dataList);
  };

  const filterData = (supplier = selectedSupplier, locationId = selectedLocation, selectedDocStatuses) => {
    let filteredData = dataList; // Use dataList as the base dataset
    if (supplier) {
      filteredData = filteredData.filter((d) => d.supplier_id === supplier);
    }
    
    if (locationId) {
      filteredData = filteredData.filter((d) => d.location_id === locationId);
    }

    if (selectedDocStatuses) {
      if (selectedDocStatuses && selectedDocStatuses.length == 0) {
        selectedDocStatuses = docStatuses.map(d => d.doc_status_id);
      }
      filteredData = filteredData.filter((d) => selectedDocStatuses.includes(d.doc_status_id));
    }
    
    setFilteredDataList(filteredData);
  };

  const updateInvoice = async (updatedData) => {
    // Ensure only the necessary fields are sent
    const formattedData = {
      location_id: updatedData.location_id,
      supplier_id: updatedData.supplier_id,
      nominal_code_id: updatedData.nominal_code_id || null, // Send null if nominal_code_id is not present
      doc_status_id: updatedData.doc_status_id,
      invoice_date: revertDateFormat(updatedData.invoice_date), // Convert to required format
      invoice_number: updatedData.invoice_number,
      order_number: updatedData.order_number,
      net: parseFloat(updatedData.net), // Ensure it is a number
      vat: parseFloat(updatedData.vat), // Ensure it is a number
      total: parseFloat(updatedData.total), // Ensure it is a number
      notes: updatedData.notes,
      credit_note: updatedData.credit_note,
      id: updatedData.id,
    };
  
    try {
      //console.log('Updating document:', JSON.stringify(formattedData, null, 2));
      const response = await axios.put(`${API_BASE_URL}/api/v1/doc`, formattedData, {
        headers: {
          Authorization: `Bearer ${AUTH_TOKEN}`,
          'Content-Type': 'application/json'
        },
      });
      
      //console.log('Update successful:', response.data);
      return response.data; // Return response data to check success in calling functions
    } catch (error) {
      //console.error('Error updating document:', error);
      throw error.response.data; // Throw error response data
    }
  };

  // Modified advanceToNextDocument function
  const advanceToNextDocument = async () => {
    try {
      // Fetch data for the current page
      const data = await fetchData(currentPage);
      //console.log('Current page data:', data);

      if (data.length > 0) {
        // If there's data on the current page, display the first document
        handleCardClick(data[0]);
      } else if (currentPage > 1) {
        // If the current page is empty and is not the first page, go to the first page
        const firstPageData = await fetchData(1);
        //console.log('First page data:', firstPageData);

        if (firstPageData.length > 0) {
          // If there's data on page 1, display the first document
          handleCardClick(firstPageData[0]);
        } else {
          // If both current page and page 1 are empty, return to GridView
          handleBackClick();
        }
      } else {
        // If currentPage is 1 and there's no data
        handleBackClick();
      }
    } catch (error) {
      console.error('Error advancing to the next document:', error);
    }
  };

  const handleSaveChanges = async () => {
    const formattedData = {
      ...tempEditableData,
      net: parseFloat(tempEditableData.net).toFixed(2),
      vat: parseFloat(tempEditableData.vat).toFixed(2),
      total: parseFloat(tempEditableData.total).toFixed(2),
    };
  
    try {
      await updateInvoice(formattedData);
      setNotification({ message: 'Changes saved successfully', type: 'success', open: true });
      setEditableData(formattedData);
      setIsEdited(false);

    } catch (errorData) {
      let errorMessage = 'Submission failed. Please try again.';
      if (errorData.errors && Array.isArray(errorData.errors)) {
        errorMessage = errorData.errors.map(err => err.msg).join(', ');
      } else if (errorData.error) {
        errorMessage = errorData.error;
      }
      setNotification({ message: errorMessage, type: 'error', open: true });
      await handleCardClick(tempEditableData);
    }
  };

  const handleApproveForImport = async () => {
    const formattedData = {
      ...tempEditableData,
      doc_status_id: 1,
    };
  
    try {
      await updateInvoice(formattedData);
      setNotification({ message: 'Document approved for import', type: 'success', open: true });
      await advanceToNextDocument();

    } catch (errorData) {
      let errorMessage = 'Submission failed. Please try again.';
      if (errorData.errors && Array.isArray(errorData.errors)) {
        errorMessage = errorData.errors.map(err => err.msg).join(', ');
      } else if (errorData.error) {
        errorMessage = errorData.error;
      }
      setNotification({ message: errorMessage, type: 'error', open: true });
      await handleCardClick(tempEditableData);
    }
  };

  const handleRejectDocument = async (selectedStatus, notes) => {
    const formattedData = {
      ...tempEditableData,
      doc_status_id: selectedStatus,
      notes: notes
    };
  
    try {
      await updateInvoice(formattedData);
      setNotification({ message: 'Document rejected', type: 'success', open: true });
      await advanceToNextDocument();

    } catch (errorData) {
      let errorMessage = 'Submission failed. Please try again.';
      if (errorData.errors && Array.isArray(errorData.errors)) {
        errorMessage = errorData.errors.map(err => err.msg).join(', ');
      } else if (errorData.error) {
        errorMessage = errorData.error;
      }
      setNotification({ message: errorMessage, type: 'error', open: true });
      await handleCardClick(tempEditableData);
    }
  };

  const handleZoomToggle = () => {
    setZoomEnabled((prevZoomEnabled) => {
      if (prevZoomEnabled) {
        zoomOut();
      }
      return !prevZoomEnabled;
    });
  };

  const zoomOut = () => {
    if (transformWrapperRef.current) {
      transformWrapperRef.current.resetTransform();
    }
  };

  const getLocationOptionLabel = (option) => {
    if (typeof option === 'string') {
      return option;
    }
    return `${option.name}`;
  };

  const getSupplierOptionLabel = (option) => {
    if (typeof option === 'string') {
      return option;
    }
    return `${option.supplier_id} - ${option.name}`;
  };

  const getNominalCodeOptionLabel = (option) => {
    if (!option) {
      return '';
    }
    if (typeof option === 'string') {
      return option;
    }
    return `${option.nominal_code_id} - ${option.name}`;
  };

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };


  const currentPageRef = useRef(currentPage);
  const pageLimitRef = useRef(pageLimit);
  const sortCriteriaRef = useRef(sortCriteria);
  const sortOrderRef = useRef(sortOrder);
  const selectedSupplierRef = useRef(selectedSupplier);
  const selectedLocationRef = useRef(selectedLocation);
  const selectedDocStatusesRef = useRef(selectedDocStatuses);
  const filteredDataListRef = useRef(filteredDataList);

  useEffect(() => {
    currentPageRef.current = currentPage;
    pageLimitRef.current = pageLimit;
    sortCriteriaRef.current = sortCriteria;
    sortOrderRef.current = sortOrder;
    selectedSupplierRef.current = selectedSupplier;
    selectedLocationRef.current = selectedLocation;
    selectedDocStatusesRef.current = selectedDocStatuses;
    filteredDataListRef.current = filteredDataList;
  }, [currentPage, pageLimit, sortCriteria, sortOrder, selectedSupplier, selectedLocation, selectedDocStatuses, filteredDataList]);

  // , pageLimit, sortCriteria, selectedSupplier, selectedLocation, selectedDocStatuses, filteredDataList

  const getUpdatedData = async () => {
    try {
        // Construct query object dynamically
        const queryObject = {};

        if (sortCriteriaRef.current) {
            queryObject.sort = { [sortCriteriaRef.current]: sortOrderRef.current };
        }

        const filter = {};
        if (selectedSupplierRef.current) {
            filter.supplier_id = selectedSupplierRef.current;
        }
        if (selectedLocationRef.current) {
            filter.location_id = selectedLocationRef.current;
        }

        // alert(selectedDocStatuses);
        // console.log("==================", filteredDataListRef.current);
        const ids = filteredDataListRef.current.map((doc)=> doc.id);

        if (ids.length > 0) {
          filter.id = {
            '$in': ids
          }
        }

        // if (selectedDocStatusesRef.current.length > 0) {
        //   filter.doc_status_id = { $in: selectedDocStatusesRef.current };
        
        // } else if (docStatuses) {
        //   filter.doc_status_id = { $in: docStatuses.filter((d) => allowedDocStatuses.includes(d.name)).map((d) => d.doc_status_id) };
  
        // }
        // console.log("Filter ====>", filter);
        // Add filter to queryObject if there are filter conditions
        if (Object.keys(filter).length > 0) {
            queryObject.filter = filter;
        }

        const queryBase64 = await fetchBase64(queryObject);
        // console.log('Query base64:', queryBase64);
        // console.log("Getting the updated data =========", currentPage, pageLimit, selectedDocStatuses, filter);
        // await sleep(2000);
        const uploadsResponse = await axios.get(`${API_BASE_URL}/api/v1/doc?query=${queryBase64}`, { headers: { Authorization: `Bearer ${AUTH_TOKEN}` } });
        // console.log("Updated data fetched =========");

        const formattedDocuments = uploadsResponse.data.data.documents.map(doc => ({
            ...doc,
            invoice_date: formatDate(doc.invoice_date),
            created_on: formatDate(doc.created_on), // Format "created_on" date
        }));
       
        // const totalDocs = uploadsResponse.data.data.total_documents || 0;
        // const totalPages = Math.ceil(totalDocs / pageLimit);
        // Update state only once at the end of this function
        // setDataList(formattedDocuments);

        const formattedDocumentsMap = {};
        formattedDocuments.forEach((data) => {
          formattedDocumentsMap[data.id] = data;
        });
        const documentIds = Object.keys(formattedDocumentsMap);
        
        let documentChanged = false;
        const tempFilteredDataList = JSON.parse(JSON.stringify(filteredDataListRef.current));
        for (let index = 0; index < filteredDataListRef.current.length; index++) {
          if (documentIds.includes(tempFilteredDataList[index].id)) {
              if (selectedDocStatusesRef.current.length > 0) {
                if (selectedDocStatusesRef.current.includes(formattedDocumentsMap[tempFilteredDataList[index].id].doc_status_id)) {
                  tempFilteredDataList[index] = formattedDocumentsMap[tempFilteredDataList[index].id];
                } else {
                  delete tempFilteredDataList[index];
                
                }

              } else if (docStatuses.length > 0) {
                // console.log(documentIds);
              // console.log(docStatuses.includes(formattedDocumentsMap[tempFilteredDataList[index].id].doc_status_id), formattedDocumentsMap[tempFilteredDataList[index].id].doc_status_id, docStatuses,  JSON.stringify(tempFilteredDataList));
              
                if (docStatuses.map(d => d.doc_status_id).includes(formattedDocumentsMap[tempFilteredDataList[index].id].doc_status_id)) {
                  tempFilteredDataList[index] = formattedDocumentsMap[tempFilteredDataList[index].id];
                
                } else {
                  delete tempFilteredDataList[index];
                
                }
              }
            // filteredDataListRef.current[index] = formattedDocumentsMap[filteredDataListRef.current[index].id];
            documentChanged = true;
            // console.log(`Index ${index}=============`, tempFilteredDataList[index]);
          }
        }
        
        // console.log("===================================Filtered", currentPageRef.current, pageLimitRef.current, formattedDocuments, tempFilteredDataList);
        // console.log("===================================Filtered", documentChanged, currentPage, pageLimit, selectedDocStatuses, filter, formattedDocuments, filteredDataList);
        if (documentChanged) {
          setFilteredDataList(JSON.parse(JSON.stringify(tempFilteredDataList.filter(d => d)))); // Set filteredDataList based on fetched data
        }
        
        // setTotalDocuments(totalDocs);
        // setTotalPages(totalPages);
        return formattedDocuments;

    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };


  const fetchExportDocsCount = async() => {
    try {
        // Construct query object dynamically
        const queryObject = {
          page: 1,
          limit: 1,
          projection: ['id']
        };
        
        if (sortCriteria) {
          queryObject.sort = {};
          queryObject.sort[sortCriteria] = sortOrder;
        }

        const filter = {};
        if (selectedSupplier) {
          filter.supplier_id = selectedSupplier;
        }

        if (selectedLocation) {
          filter.location_id = selectedLocation;
        }
    
        if (selectedDocStatuses.length > 0) {
            filter['doc_status_id'] = {
              '$in': selectedDocStatuses.filter(status => [1, 9, 10].includes(status))
            }
    
        } else if (docStatuses.length > 0) {
            filter['doc_status_id'] = {
              '$in': docStatuses.filter(status => [1, 9, 10].includes(status.doc_status_id)).map(status => status.doc_status_id)
            }
    
        }

        if (Object.keys(filter).length > 0) {
            queryObject.filter = filter;
        }

        const queryBase64 = await fetchBase64(queryObject);
        const uploadsResponse = await axios.get(`${API_BASE_URL}/api/v1/doc?query=${queryBase64}`, { headers: { Authorization: `Bearer ${AUTH_TOKEN}` } });
        const newExportDocsCount = uploadsResponse.data.data.total_documents;
        // console.log("Total documents ===================", newExportDocsCount, uploadsResponse.data.data.documents);
        
        setExportDocsCount(newExportDocsCount);

    } catch (error) {
      console.error('Error fetching data:', error);
    
    }
  };


  const poolExportJob = async () => {
    const isExportJobInProgress = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/export/job/${jobId}`, { headers: { Authorization: `Bearer ${AUTH_TOKEN}` } });
        
        if (response.status === 200) {
          // console.log(`Pooling ${response.data.data.processed}/${response.data.data.total}`);
          setProcessedDocs(response.data.data.processed);
          setTotalDocs(response.data.data.total);
          setJobMessage(response.data.data.message);
          if (response.data.data.export_job_status_id === 1) {
            setJobInProgress(true);
            // console.log("===================================Pooling", currentPageRef.current, pageLimit, selectedDocStatuses, filteredDataList);
            getUpdatedData()
            // setSortedData(data);
            // console.log("Sorted Data ==============", data);
          
          } else {
            fetchExportDocsCount();
            setExportAllDocs(false);
            setExportSelectedDocs(false);
            // console.log("===================================Pooling", currentPageRef.current, pageLimit, selectedDocStatuses, filteredDataList);
            getUpdatedData()
            // setSortedData(data);
            // handlePageChange(currentPage);
            // await sleep(10000);
            // closeJobDialog();
            // alert(100)
            // console.log("Last Sorted Data ==============", data);
          
          }

          return response.data.data.export_job_status_id == 1;
        
        } else {
          console.log(response.data.error);
          return false;
        
        }

      } catch (error) {
        console.error('Error fetching detailed data:', error);
        setJobInProgress(false);
        return false;

      }
    };

    let jobStatus = true;
    while (jobStatus) {
      setShowJobBox(true);
      jobStatus = await isExportJobInProgress();
      await sleep(3000);
    }
  };

  const startExportJob = async () => {
    
    const payload = {
      page: 1,
      filter: {}
    };

    if (selectedLocation) {
      payload.filter['location_id'] = selectedLocation;
    }

    if (selectedSupplier) {
      payload.filter['supplier_id'] = selectedSupplier;
    }

    if (sortCriteria) {
      payload.sort = {};
      payload.sort[sortCriteria] = sortOrder;
    }

    if (exportSelectedDocs) {
      payload.filter['id'] = {
        '$in': sortedData.filter(d => d.export).map(d => d.id)
      }
    
    } else if (selectedDocStatuses.length > 0) {
        payload.filter['doc_status_id'] = {
          '$in': selectedDocStatuses.filter(status => [1, 9, 10].includes(status))
        }

    } else if (docStatuses.length > 0) {
        payload.filter['doc_status_id'] = {
          '$in': docStatuses.filter(status => [1, 9, 10].includes(status.doc_status_id)).map(status => status.doc_status_id)
        }

    }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/v1/export/job`, payload, {
        headers: {
          Authorization: `Bearer ${AUTH_TOKEN}`,
          'Content-Type': 'application/json'
        },
      });
      
      if (response.status === 200) {
        setExportAllDocs(false);
        setProcessedDocs(response.data.data.processed);
        setTotalDocs(response.data.data.total);
        setJobMessage(response.data.data.message);
        setStatusMessage({type: 'success', message: response.data.data.message});
        setJobInProgress(true);
        poolExportJob();
      
      } else {
        setJobInProgress(false);

      }
      
    } catch (error) {
      setJobInProgress(false);
      setStatusMessage({type: 'error', message: error.response.data.error});
      console.error('Error while creating export job:', error);

    }

  };

  useEffect(() => {
    fetchExportDocsCount();
    poolExportJob();
  }, []);



  const refetchData = async () => {
    setLoading(true);
    await fetchData(currentPage); // Fetch the data again to ensure it's up-to-date
    setLoading(false);
  };

  if (loading) {
    return <CircularProgress />;
  }
  

  return loading ? (
    <CircularProgress />
  ) : (
    <Container className="fullScreenContainer" maxWidth={false}>
      {notification.open && (
        <Snackbar open={notification.open} autoHideDuration={6000} onClose={() => setNotification({ ...notification, open: false })}>
          <Alert onClose={() => setNotification({ ...notification, open: false })} severity={notification.type}>
            {notification.message}
          </Alert>
        </Snackbar>
      )}
      {detailedData ? (
        <ExportPage
          detailedData={detailedData}
          editableData={editableData}
          tempEditableData={tempEditableData}
          sites={locations}
          suppliers={suppliers}
          nominalCodes={nominalCodes}
          docStatuses={docStatuses}
          currentImageIndex={currentImageIndex}
          isEdited={isEdited}
          zoomEnabled={zoomEnabled}
          transformWrapperRef={transformWrapperRef}
          handleBackClick={handleBackClick}
          handleFieldChange={handleFieldChange}
          handleFieldBlur={handleFieldBlur}
          handlePreviousImage={handlePreviousImage}
          handleNextImage={handleNextImage}
          handleZoomToggle={handleZoomToggle}
          handleSaveChanges={handleSaveChanges}
          handleApproveForImport={handleApproveForImport}
          handleRejectDocument={handleRejectDocument}
          otherDocuments={filteredDataList.filter(doc => doc.id !== detailedData?.id) || []}
          handleCardClick={handleCardClick}
          getLocationOptionLabel={getLocationOptionLabel}  
          getSupplierOptionLabel={getSupplierOptionLabel}
          getNominalCodeOptionLabel={getNominalCodeOptionLabel}
          refetchData={refetchData} // Pass refetchData to ReviewPage
          imageName={getImageName()} // pass image name here
          advanceToNextDocument={advanceToNextDocument}
        />
      ) : (
        <ExportGridView
          locations={locations}
          suppliers={suppliers}
          filteredDataList={Array.isArray(filteredDataList) ? filteredDataList : []}
          handleCardClick={handleCardClick}
          selectedSupplier={selectedSupplier}
          selectedLocation={selectedLocation}
          handleSupplierChange={handleSupplierChange}
          handleLocationChange={handleLocationChange}
          handleClearFilters={handleClearFilters}
          sortCriteria={sortCriteria}
          setSortCriteria={setSortCriteria}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          currentPage={currentPage}
          totalPages={totalPages} 
          handlePageChange={handlePageChange}
          totalDocuments={totalDocuments} 
          pageLimit={pageLimit} 
          selectedDocStatuses={selectedDocStatuses} 
          handleDocStatusChange={handleDocStatusChange} 
          docStatuses={docStatuses}
          startExportJob={startExportJob}
          fetchExportDocsCount={fetchExportDocsCount}
          jobInProgress={jobInProgress}
          setJobInProgress={setJobInProgress}
          processedDocs={processedDocs}
          setProcessedDocs={setProcessedDocs}
          totalDocs={totalDocs}
          setTotalDocs={setTotalDocs}
          jobMessage={jobMessage}
          setJobMessage={setJobMessage}
          showJobBox={showJobBox}
          setShowJobBox={setShowJobBox}
          exportAllDocs={exportAllDocs}
          setExportAllDocs={setExportAllDocs}
          exportSelectedDocs={exportSelectedDocs}
          setExportSelectedDocs={setExportSelectedDocs}
          exportDocsCount={exportDocsCount}
          setExportDocsCount={setExportDocsCount}
          sortedData={sortedData}
          setSortedData={setSortedData}
          statusMessage={statusMessage}
          setStatusMessage={setStatusMessage}
        />
      )}
    </Container>
  );
};


export default ReviewExportPage;