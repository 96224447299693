import React, { useEffect, useCallback, useRef, useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  IconButton,
  Snackbar,
  Alert,
  Popover
} from '@mui/material';
import {
  Clear,
  ArrowUpward,
  ArrowDownward,
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
  Add,
  Delete
} from '@mui/icons-material';
import './SupplierGridView.css';


const SupplierGridView = ({
  locations,
  suppliers,
  filteredDataList,
  handleCardClick,
  selectedSupplier,
  selectedLocation,
  handleSupplierChange,
  handleClearFilters,
  sortCriteria,
  setSortCriteria,
  sortOrder,
  setSortOrder,
  currentPage,
  totalPages,
  handlePageChange,
  totalDocuments,
  pageLimit,
  selectedDocStatuses,
  deleteSupplierMapping,
  docStatuses,
  dumpSuppliers,
  dumpInProgress,
  processedDocs,
  setProcessedDocs,
  totalDocs,
  setTotalDocs,
  showJobBox,
  setShowJobBox,
  exportDocsCount,
  setExportDocsCount,
  sortedData,
  setSortedData,
  statusMessage,
  setStatusMessage

}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredHeader, setHoveredHeader] = useState(null);
  const [hasFilters, setHasFilters] = useState(false);
  const [supplierMappingId, setSupplierMappingId] = useState(null);

  const handleFirstPageButtonClick = useCallback(() => {
    handlePageChange(1);
  }, [handlePageChange]);

  const handleBackButtonClick = useCallback(() => {
    if (currentPage > 1) {
      handlePageChange(Math.max(currentPage - 1, 1));
    }
  }, [currentPage, handlePageChange]);

  const handleNextButtonClick = useCallback(() => {
    if (currentPage < totalPages) {
      handlePageChange(Math.min(currentPage + 1, totalPages));
    }
  }, [currentPage, handlePageChange, totalPages]);

  const handleLastPageButtonClick = useCallback(() => {
    if (totalPages > 0) {
      handlePageChange(totalPages);
    }
  }, [handlePageChange, totalPages]);

  const startDocNum = (currentPage - 1) * pageLimit + 1;
  const endDocNum = Math.min(currentPage * pageLimit, totalDocuments);


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft' && currentPage > 1) {
        handleBackButtonClick();
      }
      if (event.key === 'ArrowRight' && currentPage < totalPages) {
        handleNextButtonClick();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentPage, totalPages, handleBackButtonClick, handleNextButtonClick]);

  const previousSupplierRef = useRef(selectedSupplier);
  const previousLocationRef = useRef(selectedLocation);

  useEffect(() => {
    if (
      previousSupplierRef.current !== selectedSupplier ||
      previousLocationRef.current !== selectedLocation
    ) {
      if (currentPage !== 1) {
        handleFirstPageButtonClick();
      }
      setSortCriteria(null);
      setSortOrder(null);
    }
    previousSupplierRef.current = selectedSupplier;
    previousLocationRef.current = selectedLocation;
  }, [
    selectedSupplier,
    selectedLocation,
    currentPage,
    handleFirstPageButtonClick,
    setSortCriteria,
    setSortOrder,
  ]);

  const handleDialogOpen = (supplier_mapping_id, event) => {
    setSupplierMappingId(supplier_mapping_id);
    setAnchorEl(event.currentTarget);
  };

  const handleHeaderClick = (criteria) => {
    if (sortCriteria === criteria) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortCriteria(criteria);
      setSortOrder('asc');
    }
    setHoveredHeader(null);
  };


  const handleHeaderHover = (criteria) => {
    setHoveredHeader(criteria);
  };
  
  const closePopover = () => {
    setSupplierMappingId(null);
    setAnchorEl(null);
  };
    
  const handleConfirmAction = () => {
    deleteSupplierMapping(supplierMappingId);
    setAnchorEl(null);
  };

  // sortedData = [...filteredDataList].sort((a, b) => {
  //   let valueA = a[sortCriteria];
  //   let valueB = b[sortCriteria];
  //   a.export = false;
  //   b.export = false;
  //   if (sortCriteria === 'total') {
  //     valueA = parseFloat(a.total);
  //     valueB = parseFloat(b.total);
  //   }

  //   if (sortCriteria === 'invoice_date' || sortCriteria === 'created_on') {
  //     valueA = new Date(a[sortCriteria]);
  //     valueB = new Date(b[sortCriteria]);
  //   }

  //   if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
  //   if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
  //   return 0;
  // });


  useEffect(() => {
    setSortedData([...filteredDataList].sort((a, b) => {
      let valueA = a[sortCriteria];
      let valueB = b[sortCriteria];
  
      if (sortCriteria === 'total') {
        valueA = parseFloat(a.total);
        valueB = parseFloat(b.total);
      }
  
      if (sortCriteria === 'invoice_date' || sortCriteria === 'created_on') {
        valueA = new Date(a[sortCriteria]);
        valueB = new Date(b[sortCriteria]);
      }
  
      if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    }));
  }, [filteredDataList]);

  useEffect(() => {
    setHasFilters(
      selectedSupplier !== '' ||
      selectedLocation !== '' ||
      selectedDocStatuses.some((status) => status !== 4) ||
      !!sortCriteria
    );
  }, [selectedSupplier, selectedLocation, selectedDocStatuses, sortCriteria]);

  return (
    <div>

      <Grid container spacing={4}>
        
        <Grid item xs={12} md={3} style={{ "padding-top": '70px' }}>
          {
            <Box className="filterElement">
              <Button
                onClick={dumpSuppliers}
                disabled={dumpInProgress}
                className="pageButton"
              >
                Dump Suppliers
              </Button>
            </Box>
          }
          <Box className="filterElement">
            <Typography variant="subtitle1" className="filterTitle">
              Filter by Supplier
            </Typography>
            <Autocomplete
              options={suppliers}
              value={
                selectedSupplier
                  ? suppliers.find(
                      (supplier) => supplier.supplier_id === selectedSupplier
                    )
                  : null
              }
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <li {...props}>
                  <span>{option.name}</span>
                </li>
              )}
              onChange={(event, newValue) =>
                handleSupplierChange({
                  target: { value: newValue ? newValue.supplier_id : '' },
                })
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="All Suppliers" fullWidth />
              )}
            />
          </Box>
          {hasFilters && (
            <Box className="clearButton">
              <Button
                variant="outlined"
                startIcon={<Clear />}
                onClick={handleClearFilters}
              >
                Clear Filters
              </Button>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={9}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={() => handleCardClick({})} color="primary" aria-label="delete">
            <Add />
          </IconButton>
        </div>
          <TableContainer component={Paper} style={{ width: '100%' }}>
            <Table style={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={`sortableHeader ${
                      sortCriteria === 'supplier_id' ? 'sorted' : ''
                    }`}
                    onClick={() => handleHeaderClick('supplier_id')}
                    onMouseEnter={() => handleHeaderHover('supplier_id')}
                    onMouseLeave={() => setHoveredHeader(null)}
                  >
                    <div className="headerContent">
                      <strong>Supplier Id</strong>
                      {
                        (sortCriteria === 'supplier_id' || hoveredHeader === 'supplier_id') &&
                        (sortCriteria === 'supplier_id' ? (
                          sortOrder === 'asc' ? (
                            <ArrowUpward className="sortIcon" />
                          ) : (
                            <ArrowDownward className="sortIcon" />
                          )
                        ) : (
                          <ArrowDownward className="hoverIcon" />
                        ))
                      }
                    </div>
                  </TableCell>
                  <TableCell
                    className={`sortableHeader ${
                      sortCriteria === 'name' ? 'sorted' : ''
                    }`}
                    onClick={() => handleHeaderClick('name')}
                    onMouseEnter={() => handleHeaderHover('name')}
                    onMouseLeave={() => setHoveredHeader(null)}
                  >
                    <div className="headerContent">
                      <strong>Name</strong>
                      {
                        (sortCriteria === 'name' || hoveredHeader === 'name') &&
                        (sortCriteria === 'name' ? (
                          sortOrder === 'asc' ? (
                            <ArrowUpward className="sortIcon" />
                          ) : (
                            <ArrowDownward className="sortIcon" />
                          )
                        ) : (
                          <ArrowDownward className="hoverIcon" />
                        ))
                      }
                    </div>
                  </TableCell>
                  <TableCell
                    className={`sortableHeader ${
                      sortCriteria === 'telephone' ? 'sorted' : ''
                    }`}
                    onClick={() => handleHeaderClick('telephone')}
                    onMouseEnter={() => handleHeaderHover('telephone')}
                    onMouseLeave={() => setHoveredHeader(null)}
                  >
                    <div className="headerContent">
                      <strong>Telephone</strong>
                      {
                        (sortCriteria === 'telephone' || hoveredHeader === 'telephone') &&
                        (sortCriteria === 'telephone' ? (
                          sortOrder === 'asc' ? (
                            <ArrowUpward className="sortIcon" />
                          ) : (
                            <ArrowDownward className="sortIcon" />
                          )
                        ) : (
                          <ArrowDownward className="hoverIcon" />
                        ))
                      }
                    </div>
                  </TableCell>
                  <TableCell
                    className={`sortableHeader ${
                      sortCriteria === 'email' ? 'sorted' : ''
                    }`}
                    onClick={() => handleHeaderClick('email')}
                    onMouseEnter={() => handleHeaderHover('email')}
                    onMouseLeave={() => setHoveredHeader(null)}
                  >
                    <div className="headerContent">
                      <strong>Email</strong>
                      {(sortCriteria === 'email' ||
                        hoveredHeader === 'email') &&
                        (sortCriteria === 'email' ? (
                          sortOrder === 'asc' ? (
                            <ArrowUpward className="sortIcon" />
                          ) : (
                            <ArrowDownward className="sortIcon" />
                          )
                        ) : (
                          <ArrowDownward className="hoverIcon" />
                        ))}
                    </div>
                  </TableCell>
                  <TableCell
                    className={`sortableHeader ${
                      sortCriteria === 'website' ? 'sorted' : ''
                    }`}
                    onClick={() => handleHeaderClick('website')}
                    onMouseEnter={() => handleHeaderHover('website')}
                    onMouseLeave={() => setHoveredHeader(null)}
                  >
                    <div className="headerContent">
                      <strong>Website</strong>
                      {(sortCriteria === 'website' ||
                        hoveredHeader === 'website') &&
                        (sortCriteria === 'website' ? (
                          sortOrder === 'asc' ? (
                            <ArrowUpward className="sortIcon" />
                          ) : (
                            <ArrowDownward className="sortIcon" />
                          )
                        ) : (
                          <ArrowDownward className="hoverIcon" />
                        ))}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="headerContent">
                      <strong>Delete</strong>
                    </div>
                  </TableCell>
                  {/* <TableCell
                    className={`sortableHeader ${
                      sortCriteria === 'created_on' ? 'sorted' : ''
                    }`}
                    onClick={() => handleHeaderClick('created_on')}
                    onMouseEnter={() => handleHeaderHover('created_on')}
                    onMouseLeave={() => setHoveredHeader(null)}
                  >
                  <div className="headerContent">
                    <strong>Created On</strong>
                    {
                      (sortCriteria === 'created_on' || hoveredHeader === 'created_on') &&
                      (sortCriteria === 'created_on' ? (
                        sortOrder === 'asc' ? (
                          <ArrowUpward className="sortIcon" />
                        ) : (
                          <ArrowDownward className="sortIcon" />
                        )
                      ) : (
                        <ArrowDownward className="hoverIcon" />
                      ))
                    }
                  </div>
                </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  sortedData.length > 0?
                  sortedData.map((data, index) => (
                    <TableRow
                      key={index}
                      className="tableRow"
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell onClick={() => handleCardClick(data)}>{data.supplier_id}</TableCell>
                      <TableCell onClick={() => handleCardClick(data)}>{data.name}</TableCell>
                      <TableCell onClick={() => handleCardClick(data)}>{data.telephone}</TableCell>
                      <TableCell onClick={() => handleCardClick(data)}>{data.email}</TableCell>
                      <TableCell onClick={() => handleCardClick(data)}>{data.website}</TableCell>
                      <TableCell>
                        <IconButton onClick={(e) => handleDialogOpen(data.id, e)} color="primary" aria-label="delete">
                          <Delete />
                        </IconButton>
                      </TableCell>
                      {/* <TableCell onClick={() => handleCardClick(data)}>{data.created_on}</TableCell> */}
                    </TableRow>
                  )):
                  <TableRow
                    className="tableRow"
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>No record(s) found.</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Typography variant="body2">
              Showing {startDocNum} - {endDocNum} of {totalDocuments} results
            </Typography>
          </Box>

          <Box className="paginationContainer">
            <Button
              onClick={handleFirstPageButtonClick}
              disabled={currentPage === 1}
              className="pageButton"
              startIcon={<FirstPage />}
            >
              First
            </Button>
            <Button
              onClick={handleBackButtonClick}
              disabled={currentPage === 1}
              className="pageButton"
              startIcon={<NavigateBefore />}
            >
              Back
            </Button>
            <Button
              onClick={handleNextButtonClick}
              disabled={currentPage === totalPages}
              className="pageButton"
              endIcon={<NavigateNext />}
            >
              Next
            </Button>
            <Button
              onClick={handleLastPageButtonClick}
              disabled={currentPage === totalPages}
              className="pageButton"
              endIcon={<LastPage />}
            >
              Last
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!statusMessage.message}
        autoHideDuration={6000}
        onClose={() => setStatusMessage({ type: '', message: '' })}
      >
        <Alert onClose={() => setStatusMessage({ type: '', message: '' })} severity={statusMessage.type} sx={{ width: '100%' }}>
          {statusMessage.message}
        </Alert>
      </Snackbar>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Box p={2}>
          <Box mt={1} display="flex" justifyContent="space-between">
            <Button  style={{ "margin-right": '10px' }} variant="outlined" onClick={closePopover}>Cancel</Button>
            <Button variant="contained" color={'error'} onClick={handleConfirmAction}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>

    
  );
};

export default SupplierGridView;