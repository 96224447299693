import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Grid, Typography, Button, Box, IconButton, Popover, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Paper, Pagination, MenuItem, Select, FormControl, InputLabel, TextField
} from '@mui/material';
import { 
  ArrowBack, ArrowLeft, ArrowRight, ZoomIn, ZoomOut, FileDownload, CheckCircle as CheckCircleIcon, 
  Cancel as CancelIcon
} from '@mui/icons-material';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import ExtractedFields from './ExtractedFields';

const ReviewPage = ({
  detailedData, editableData, tempEditableData, sites, suppliers, nominalCodes, docStatuses, 
  currentImageIndex, isEdited, zoomEnabled, transformWrapperRef, handleBackClick, handleFieldChange, 
  handleFieldBlur, handlePreviousImage, handleNextImage, handleZoomToggle, handleSaveChanges, 
  handleApproveForImport, handleRejectDocument, otherDocuments, handleCardClick, getLocationOptionLabel, 
  getSupplierOptionLabel, getNominalCodeOptionLabel, refetchData, imageName
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRejectReason, setSelectedRejectReason] = useState('');
  const [notes, setNotes] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [polygonProperty, setPolygonProperty] = useState('');
  const [dialogType, setDialogType] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const imageRef = useRef(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const supplierMap = suppliers.reduce((acc, supplier) => {
    acc[supplier.supplier_id] = supplier.name;
    return acc;

  }, {});

  const siteMap = sites.reduce((acc, site) => {
    acc[site.location_id] = site.name;
    return acc;
  }, {});

  const validateAllFields = useCallback(() => {
    let errors = {};
    const requiredFields = [
      'location_id', 'supplier_id', 'nominal_code_id', 'invoice_date', 
      'invoice_number', 'order_number', 'net', 'vat', 'total'
    ];
    requiredFields.forEach(field => {
      if (!tempEditableData[field]) {
        errors[field] = 'This field is required';
      }
    });
    setValidationErrors(errors);
  }, [tempEditableData]);

  useEffect(() => {
    validateAllFields();
  }, [tempEditableData, validateAllFields]);

  const handleConfirmAction = () => {
    if (dialogType === 'approve' && Object.keys(validationErrors).length > 0) {
      return; // Don't proceed with approval if there are validation errors
    }
  
    if (dialogType === 'approve') {
      handleApproveForImport();

    } else if (dialogType === 'reject') {
      // Clear nominal_code_id errors for rejection
      handleRejectDocument(selectedRejectReason, notes);
    }
  
    setDialogType('');
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const paginatedOtherDocuments = otherDocuments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'ArrowLeft') {
      handlePreviousImage();
    } else if (event.key === 'ArrowRight') {
      handleNextImage();
    }
  }, [handlePreviousImage, handleNextImage]);


  const handleNotesChange = (event) => {
    const notes = event.target.value;
    setNotes(notes);
  };


  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const polygonPropertyRef = useRef(polygonProperty);

  useEffect(() => {
    polygonPropertyRef.current = polygonProperty;
    updateImageSize(polygonProperty);
  }, [polygonProperty]);

  

  const updateImageSize = useCallback((property) => {
    if (typeof property !== 'string') {
      property = polygonPropertyRef.current;
    }
    // console.log("Polygon Property =>", polygonPropertyRef.current, detailedData?.polygons);
    if (imageRef.current && property) {
      const pageProperty = detailedData?.polygons.filter((polygon) => polygon?.property === property)[0];
      
      if (pageProperty) {
        if (currentImageIndex < pageProperty.page - 1) {
          handleNextImage();
        
        } else if (currentImageIndex > pageProperty.page - 1) {
          handlePreviousImage();
          
        }
      
      
        const img = new Image();
        
        img.src = detailedData.images[pageProperty.page - 1].data;
        
        // Once the image is loaded, we can get its dimensions
        img.onload = () => {
          setImageSize({
            width: imageRef.current.clientWidth,
            height: imageRef.current.clientHeight
          });

          const padding = 2;
          const scaledPolygons = [];
          
          detailedData?.polygons.forEach((polygon) => {
            const originalWidth = img.width;
            const originalHeight = img.height;

            const scaleX = imageRef.current.clientWidth / originalWidth;
            const scaleY = imageRef.current.clientHeight / originalHeight;
            
            const points = [];
            polygon?.points?.forEach((point, index) => {
              if (index === 0) {
                points.push({x: Math.round(point[0] * scaleX) - padding , y: Math.round(point[1] * scaleY) - padding});
              
              } else if (index === 1) {
                points.push({x: Math.round(point[0] * scaleX) + padding , y: Math.round(point[1] * scaleY) - padding});
              
              } else if (index === 2) {
                points.push({x: Math.round(point[0] * scaleX) + padding , y: Math.round(point[1] * scaleY) + padding});
              
              } else {
                points.push({x: Math.round(point[0] * scaleX) - padding , y: Math.round(point[1] * scaleY) + padding});
              
              }
            });

            scaledPolygons.push({
              page: polygon?.page,
              display: property === polygon?.property,
              points: points,
            });
          });
          
          setPolygons(scaledPolygons);
        };
      }
    }
  });

  // Update image dimensions when the image loads
  useEffect(() => {
    // Update dimensions on mount and resize
    // updateImageSize();
    window.addEventListener('resize', updateImageSize);
    return () => {
      window.removeEventListener('resize', updateImageSize);
    };
}, []);

  const handleDownload = () => {
    // Convert Base64 string to a Blob object
    const pdfBlob = new Blob([Uint8Array.from(atob(detailedData.files[0].data), c => c.charCodeAt(0))], { type: 'application/pdf' });
    
    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(pdfBlob);
    link.download = `${detailedData.files[0].name.split("/")[1]}`; // The file name that will be downloaded
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link
    document.body.removeChild(link);
  };

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: '10px' }} alignItems="center">
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button variant="outlined" startIcon={<ArrowBack />} onClick={handleBackClick}>
            Return to Grid
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={4}>

        <Grid item xs={12} md={8}>
          <div style={{ position: 'relative', display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={handleDownload}>    
              Download <FileDownload/>
            </IconButton>
          </div>
          <div style={{ position: 'relative', display: 'flex', alignItems: 'center', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)' }}>
            <TransformWrapper ref={transformWrapperRef} disabled={!zoomEnabled}>
              <TransformComponent>
                <div style={{ position: 'relative', width: '100%' }}>
                    {/* Image */}
                    <img
                        ref={imageRef}
                        src={detailedData.images[currentImageIndex].data}
                        alt="Document"
                        style={{
                            width: '100%',
                            // borderRadius: '8px',
                            // marginBottom: '10px',
                            cursor: zoomEnabled ? 'zoom-in' : 'default'
                        }}
                    />

                    {/* Overlay with SVG polygons, matching the image dimensions */}
                    <svg
                        width={imageSize.width}
                        height={imageSize.height}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            pointerEvents: 'none', // Prevent interaction with the SVG overlay
                        }}
                    >
                        {
                          polygons.map((polygon, index) => (
                            polygon?.display && polygon?.page === currentImageIndex + 1 &&
                            <polygon
                                key={index}
                                points={polygon?.points.map(point => `${point.x},${point.y}`).join(' ')}
                                stroke="red"
                                strokeWidth="2"
                                fill="none"  // No fill, just the border
                            />
                          ))
                        }
                    </svg>
                  </div> 
              </TransformComponent>
            </TransformWrapper>
            <IconButton
              onClick={handleZoomToggle}
              sx={{
                position: 'absolute', top: '10px', right: '10px',
                backgroundColor: 'rgba(220, 220, 220, 0.5)',
                '&:hover': { backgroundColor: 'rgba(180, 180, 180, 0.5)' }
              }}
            >
              {zoomEnabled ? <ZoomOut /> : <ZoomIn />}
            </IconButton>
          </div>

          <Box display="flex" justifyContent="center" mt={2}>
            <IconButton onClick={handlePreviousImage} disabled={currentImageIndex === 0}>
              <ArrowLeft style={{ fontSize: '32px' }} />
            </IconButton>
            <IconButton onClick={handleNextImage} disabled={currentImageIndex === detailedData.images.length - 1}>
              <ArrowRight style={{ fontSize: '32px' }} />
            </IconButton>
          </Box>

          <Typography variant="h6" gutterBottom>Other Documents</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Location</strong></TableCell>
                  <TableCell><strong>Supplier</strong></TableCell>
                  <TableCell><strong>Invoice Date</strong></TableCell>
                  <TableCell><strong>Total</strong></TableCell>
                  <TableCell><strong>Document ID</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedOtherDocuments.map((data, index) => (
                  <TableRow key={index} onClick={() => handleCardClick(data.id)} style={{ cursor: 'pointer' }}>
                    <TableCell>{siteMap[data.location_id] || 'N/A'}</TableCell>
                    <TableCell>{supplierMap[data.supplier_id] || 'N/A'}</TableCell>
                    <TableCell>{data.invoice_date}</TableCell>
                    <TableCell>{(parseFloat(data.total)).toFixed(2)}</TableCell>
                    <TableCell>{data.invoice_number}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {otherDocuments.length > itemsPerPage && (
            <Box mt={2} display="flex" justifyContent="center">
              <Pagination
                count={Math.ceil(otherDocuments.length / itemsPerPage)}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <ExtractedFields
            tempEditableData={tempEditableData}
            sites={sites}
            suppliers={suppliers}
            nominalCodes={nominalCodes}
            handleFieldChange={handleFieldChange}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            dateInput={tempEditableData.invoice_date}
            setDateInput={(value) => handleFieldChange('invoice_date', value)}
            isEdited={isEdited}
            handleSaveChanges={handleSaveChanges}
            handleDialogOpen={(type, event) => {
              setDialogType(type);
              setAnchorEl(event.currentTarget);
            }}
            getLocationOptionLabel={getLocationOptionLabel}
            getSupplierOptionLabel={getSupplierOptionLabel}
            getNominalCodeOptionLabel={getNominalCodeOptionLabel}
            refetchData={refetchData}
            imageName={imageName} // pass image name here
            setPolygonProperty={setPolygonProperty}
          />
        </Grid>
      </Grid>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Box p={2}>
          <Box display="flex" alignItems="center" mb={2} color={dialogType === 'approve' ? 'green' : 'red'}>
            {dialogType === 'approve' ? <CheckCircleIcon style={{ marginRight: '8px' }} /> : <CancelIcon style={{ marginRight: '8px' }} />}
            <Typography variant="h6">{dialogType === 'approve' ? "Approve Document" : "Reject Document"}</Typography>
          </Box>
          
          
          {dialogType === 'reject' && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="reject-reason-select-label">Reject Reason</InputLabel>
              <Select
                labelId="reject-reason-select-label"
                value={selectedRejectReason}
                onChange={(e) => setSelectedRejectReason(e.target.value)}
              >
                {
                  docStatuses.filter(status => [2, 3, 5, 6].includes(status.doc_status_id)).map(status => (
                    <MenuItem key={status.doc_status_id} value={status.doc_status_id}>
                      {status.name}
                    </MenuItem>
                  ))
                }
              </Select>

              <div style={{ marginTop: '10px', position: 'relative' }}>
                <TextField
                  value={notes}
                  onChange={handleNotesChange}
                  label="Notes"
                  multiline
                  rows={5}
                  variant="outlined"
                />
              </div>
            </FormControl>
          )}

          <Box mt={1} display="flex" justifyContent="space-between">
            <Button variant="outlined" onClick={() => setAnchorEl(null)}>Cancel</Button>
            <Button
              variant="contained"
              color={dialogType === 'approve' ? 'success' : 'error'}
              onClick={handleConfirmAction}
              disabled={dialogType === 'reject' && !selectedRejectReason}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default ReviewPage;