import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

// Fetch user data from environment variables
const users = [
  {
    username: process.env.REACT_APP_UPLOAD_USER_USERNAME,
    password: process.env.REACT_APP_UPLOAD_USER_PASSWORD,
    role: process.env.REACT_APP_UPLOAD_USER_ROLE,
  },
  {
    username: process.env.REACT_APP_REVIEW_USER_USERNAME,
    password: process.env.REACT_APP_REVIEW_USER_PASSWORD,
    role: process.env.REACT_APP_REVIEW_USER_ROLE,
  },
  {
    username: process.env.REACT_APP_ADMIN_USER_USERNAME,
    password: process.env.REACT_APP_ADMIN_USER_PASSWORD,
    role: process.env.REACT_APP_ADMIN_USER_ROLE,
  },
  {
    username: process.env.REACT_APP_BOGNOR_UPLOAD_USERNAME,
    password: process.env.REACT_APP_BOGNOR_UPLOAD_PASSWORD,
    role: process.env.REACT_APP_UPLOAD_USER_ROLE,
  },
  {
    username: process.env.REACT_APP_ROCHDALE_UPLOAD_USERNAME,
    password: process.env.REACT_APP_ROCHDALE_UPLOAD_PASSWORD,
    role: process.env.REACT_APP_UPLOAD_USER_ROLE,
  },
  {
    username: process.env.REACT_APP_INNOVATION_UPLOAD_USERNAME,
    password: process.env.REACT_APP_INNOVATION_UPLOAD_PASSWORD,
    role: process.env.REACT_APP_UPLOAD_USER_ROLE,
  },
  {
    username: process.env.REACT_APP_PORTSMOUTH_UPLOAD_USERNAME,
    password: process.env.REACT_APP_PORTSMOUTH_UPLOAD_PASSWORD,
    role: process.env.REACT_APP_UPLOAD_USER_ROLE
  },
  {
    username: process.env.REACT_APP_BRYAN_UPLOAD_USERNAME,
    password: process.env.REACT_APP_BRYAN_UPLOAD_PASSWORD,
    role: process.env.REACT_APP_UPLOAD_USER_ROLE
  }
];

export function AuthProvider({ children }) {
  const initialAuthState = JSON.parse(localStorage.getItem('authState')) || { isAuthenticated: false, userRole: null };
  const [isAuthenticated, setIsAuthenticated] = useState(initialAuthState.isAuthenticated);
  const [userRole, setUserRole] = useState(initialAuthState.userRole);

  useEffect(() => {
    localStorage.setItem('authState', JSON.stringify({ isAuthenticated, userRole }));
  }, [isAuthenticated, userRole]);

  const login = (username, password, rememberMe) => {
    
    const user = users.find(user => user.username.toLowerCase() === username.toLowerCase() && user.password === password);
    if (user) {
      setIsAuthenticated(true);
      setUserRole(user.role);
      if (rememberMe) {
        localStorage.setItem('authState', JSON.stringify({
          isAuthenticated: true,
          userRole: user.role,
        }));
      }
      return { success: true, role: user.role };
    } else {
      return { success: false, role: null };
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserRole(null);
    localStorage.removeItem('authState');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userRole, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}