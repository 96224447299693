import React, { useEffect, useCallback, useRef, useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  Checkbox,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
  Snackbar,
  Alert
} from '@mui/material';
import {
  Clear,
  ArrowUpward,
  ArrowDownward,
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
  FilterList,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/joy/CircularProgress';
import './ExportGridView.css';


const ExportGridView = ({
  locations,
  suppliers,
  filteredDataList,
  handleCardClick,
  selectedSupplier,
  selectedLocation,
  handleSupplierChange,
  handleLocationChange,
  handleClearFilters,
  sortCriteria,
  setSortCriteria,
  sortOrder,
  setSortOrder,
  currentPage,
  totalPages,
  handlePageChange,
  totalDocuments,
  pageLimit,
  selectedDocStatuses,
  handleDocStatusChange,
  docStatuses,
  startExportJob,
  fetchExportDocsCount,
  jobInProgress,
  setJobInProgress,
  processedDocs,
  setProcessedDocs,
  totalDocs,
  setTotalDocs,
  jobMessage,
  setJobMessage,
  showJobBox,
  setShowJobBox,
  exportAllDocs,
  setExportAllDocs,
  exportSelectedDocs,
  setExportSelectedDocs,
  exportDocsCount,
  setExportDocsCount,
  sortedData,
  setSortedData,
  statusMessage,
  setStatusMessage

}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredHeader, setHoveredHeader] = useState(null);
  const [hasFilters, setHasFilters] = useState(false);
  
  const open = Boolean(anchorEl);

  const supplierMap = suppliers.reduce((acc, supplier) => {
    acc[supplier.supplier_id] = supplier.name;
    return acc;

  }, {});

  const locationMap = locations.reduce((acc, location) => {
    acc[location.location_id] = location.name;
    return acc;
  }, {});

  const statusMap = docStatuses.reduce((acc, status) => {
    acc[status.doc_status_id] = status.name;
    return acc;
  }, {});

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const handleFirstPageButtonClick = useCallback(() => {
      handlePageChange(1);
  }, [handlePageChange]);

  const handleBackButtonClick = useCallback(() => {
    if (currentPage > 1) {
      handlePageChange(Math.max(currentPage - 1, 1));
    }
  }, [currentPage, handlePageChange]);

  const handleNextButtonClick = useCallback(() => {
    if (currentPage < totalPages) {
      handlePageChange(Math.min(currentPage + 1, totalPages));
    }
  }, [currentPage, handlePageChange, totalPages]);

  const handleLastPageButtonClick = useCallback(() => {
    if (totalPages > 0) {
      handlePageChange(totalPages);
    }
  }, [handlePageChange, totalPages]);

  const startDocNum = (currentPage - 1) * pageLimit + 1;
  const endDocNum = Math.min(currentPage * pageLimit, totalDocuments);


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft' && currentPage > 1) {
        handleBackButtonClick();
      }
      if (event.key === 'ArrowRight' && currentPage < totalPages) {
        handleNextButtonClick();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentPage, totalPages, handleBackButtonClick, handleNextButtonClick]);

  const previousSupplierRef = useRef(selectedSupplier);
  const previousLocationRef = useRef(selectedLocation);

  useEffect(() => {
    if (
      previousSupplierRef.current !== selectedSupplier ||
      previousLocationRef.current !== selectedLocation
    ) {
      if (currentPage !== 1) {
        handleFirstPageButtonClick();
      }
      setSortCriteria(null);
      setSortOrder(null);
    }
    previousSupplierRef.current = selectedSupplier;
    previousLocationRef.current = selectedLocation;
  }, [
    selectedSupplier,
    selectedLocation,
    currentPage,
    handleFirstPageButtonClick,
    setSortCriteria,
    setSortOrder,
  ]);

  const handleHeaderClick = (criteria) => {
    if (sortCriteria === criteria) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortCriteria(criteria);
      setSortOrder('asc');
    }
    setHoveredHeader(null);
  };


  const handleHeaderHover = (criteria) => {
    setHoveredHeader(criteria);
  };
 

  const selectAllDocs = (event) => {
    setExportAllDocs(event.target.checked);
    setExportSelectedDocs(event.target.checked);
    sortedData.filter(data => [1, 9, 10].includes(data.doc_status_id)).forEach((data) => {
      data.export = event.target.checked;
    });
    
    if (event.target.checked) {
      setExportDocsCount(sortedData.filter(data => data.export).length);
    
    } else {
      fetchExportDocsCount();
    
    }
  };
  

  const selectSingleDoc = (e, index) => {
    setExportAllDocs(false);
    sortedData[index].export = e.target.checked;
    setExportSelectedDocs(sortedData.some(d =>  d.export));
    
    if (sortedData.filter(data => data.export).length === 0) {
      fetchExportDocsCount();
    
    } else {
      setExportDocsCount(sortedData.filter(data => data.export).length);
    
    }
  };

  const closeJobDialog = () => {
    setJobInProgress(false);
  };

  // sortedData = [...filteredDataList].sort((a, b) => {
  //   let valueA = a[sortCriteria];
  //   let valueB = b[sortCriteria];
  //   a.export = false;
  //   b.export = false;
  //   if (sortCriteria === 'total') {
  //     valueA = parseFloat(a.total);
  //     valueB = parseFloat(b.total);
  //   }

  //   if (sortCriteria === 'invoice_date' || sortCriteria === 'created_on') {
  //     valueA = new Date(a[sortCriteria]);
  //     valueB = new Date(b[sortCriteria]);
  //   }

  //   if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
  //   if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
  //   return 0;
  // });


  useEffect(() => {
    fetchExportDocsCount();
    filteredDataList = filteredDataList.map(d => {
      d.export = false;
      return d;
    });

    setSortedData([...filteredDataList].sort((a, b) => {
      let valueA = a[sortCriteria];
      let valueB = b[sortCriteria];
  
      if (sortCriteria === 'total') {
        valueA = parseFloat(a.total);
        valueB = parseFloat(b.total);
      }
  
      if (sortCriteria === 'invoice_date' || sortCriteria === 'created_on') {
        valueA = new Date(a[sortCriteria]);
        valueB = new Date(b[sortCriteria]);
      }
  
      if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    }));
  }, [filteredDataList]);
  

  const handleStatusClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusClose = () => {
    setAnchorEl(null);
  };

  const handleStatusItemClick = (statusId) => {
    handleDocStatusChange(
      selectedDocStatuses.includes(statusId)
        ? selectedDocStatuses.filter((id) => id !== statusId)
        : [...selectedDocStatuses, statusId]
    );
    setAnchorEl(null);
  };

  useEffect(() => {
    setHasFilters(
      selectedSupplier !== '' ||
      selectedLocation !== '' ||
      selectedDocStatuses.some((status) => status !== 4) ||
      !!sortCriteria
    );
  }, [selectedSupplier, selectedLocation, selectedDocStatuses, sortCriteria]);

  return (
    <div>
      <Grid container spacing={4}>
        
        <Grid item xs={12} md={3}>
          {

            showJobBox?(jobInProgress?
            <Tooltip title={`${processedDocs} / ${totalDocs} completed`} arrow>
              <Box className="filterElement">
                <Typography variant="subtitle1">
                  <IconButton 
                    sx={{
                      position: 'relative',
                      top: '0px',
                      right: '0px',
                    }}
                    onClick={closeJobDialog}>
                    <CloseIcon />
                  </IconButton>
                  {jobMessage}
                </Typography>
                <CircularProgress size="lg" determinate value={Math.floor(processedDocs / totalDocs * 100)}>
                  {Math.floor(processedDocs / totalDocs * 100)}%
                </CircularProgress>
              </Box>
            </Tooltip>:
            <Box className="filterElement">
              <Button
                onClick={startExportJob}
                disabled={exportDocsCount === 0 || jobInProgress}
                className="pageButton"
              >
                Export {exportSelectedDocs? `Selected (${exportDocsCount})`: `All (${exportDocsCount})`}
              </Button>
            </Box>): <Box className="filterElement" height={37}></Box>
          }
          <Box className="filterElement">
            <Typography variant="subtitle1" className="filterTitle">
              Filter by Location
            </Typography>
            <Autocomplete
              options={locations}
              value={
                selectedLocation
                  ? locations.find(
                      (location) => location.location_id === selectedLocation
                    )
                  : null
              }
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <li {...props}>
                  <span>{option.name}</span>
                </li>
              )}
              onChange={(event, newValue) =>
                handleLocationChange({
                  target: { value: newValue ? newValue.location_id : '' },
                })
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="All Locations" fullWidth />
              )}
            />
          </Box>
          <Box className="filterElement">
            <Typography variant="subtitle1" className="filterTitle">
              Filter by Supplier
            </Typography>
            <Autocomplete
              options={suppliers}
              value={
                selectedSupplier
                  ? suppliers.find(
                      (supplier) => supplier.supplier_id === selectedSupplier
                    )
                  : null
              }
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <li {...props}>
                  <span>{option.name}</span>
                </li>
              )}
              onChange={(event, newValue) =>
                handleSupplierChange({
                  target: { value: newValue ? newValue.supplier_id : '' },
                })
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="All Suppliers" fullWidth />
              )}
            />
          </Box>
          {hasFilters && (
            <Box className="clearButton">
              <Button
                variant="outlined"
                startIcon={<Clear />}
                onClick={handleClearFilters}
              >
                Clear Filters
              </Button>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={9}>
          <TableContainer component={Paper} style={{ width: '100%' }}>
            <Table style={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox checked={exportAllDocs} disabled={exportDocsCount === 0} onClick={(e) => selectAllDocs(e)}/>
                  </TableCell>
                  <TableCell
                    className={`sortableHeader ${
                      sortCriteria === 'created_on' ? 'sorted' : ''
                    }`}
                    onClick={() => handleHeaderClick('created_on')}
                    onMouseEnter={() => handleHeaderHover('created_on')}
                    onMouseLeave={() => setHoveredHeader(null)}
                  >
                    <div className="headerContent">
                      <strong>Uploaded On</strong>
                      {(sortCriteria === 'created_on' ||
                        hoveredHeader === 'created_on') &&
                        (sortCriteria === 'created_on' ? (
                          sortOrder === 'asc' ? (
                            <ArrowUpward className="sortIcon" />
                          ) : (
                            <ArrowDownward className="sortIcon" />
                          )
                        ) : (
                          <ArrowDownward className="hoverIcon" />
                        ))}
                    </div>
                  </TableCell>
                  <TableCell>
                    <strong>Location</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Supplier</strong>
                  </TableCell>
                  <TableCell
                    className={`sortableHeader ${
                      sortCriteria === 'invoice_date' ? 'sorted' : ''
                    }`}
                    onClick={() => handleHeaderClick('invoice_date')}
                    onMouseEnter={() => handleHeaderHover('invoice_date')}
                    onMouseLeave={() => setHoveredHeader(null)}
                  >
                    <div className="headerContent">
                      <strong>Invoice Date</strong>
                      {(sortCriteria === 'invoice_date' ||
                        hoveredHeader === 'invoice_date') &&
                        (sortCriteria === 'invoice_date' ? (
                          sortOrder === 'asc' ? (
                            <ArrowUpward className="sortIcon" />
                          ) : (
                            <ArrowDownward className="sortIcon" />
                          )
                        ) : (
                          <ArrowDownward className="hoverIcon" />
                        ))}
                    </div>
                  </TableCell>
                  <TableCell
                    className={`sortableHeader ${
                      sortCriteria === 'total' ? 'sorted' : ''
                    }`}
                    onClick={() => handleHeaderClick('total')}
                    onMouseEnter={() => handleHeaderHover('total')}
                    onMouseLeave={() => setHoveredHeader(null)}
                  >
                    <div className="headerContent">
                      <strong>Total</strong>
                      {(sortCriteria === 'total' ||
                        hoveredHeader === 'total') &&
                        (sortCriteria === 'total' ? (
                          sortOrder === 'asc' ? (
                            <ArrowUpward className="sortIcon" />
                          ) : (
                            <ArrowDownward className="sortIcon" />
                          )
                        ) : (
                          <ArrowDownward className="hoverIcon" />
                        ))}
                    </div>
                  </TableCell>
                  <TableCell>
                    <strong>Document ID</strong>
                  </TableCell>
                  <TableCell
                    className="filterStatusHeader"
                    onClick={handleStatusClick}
                  >
                    <div className="headerContent">
                      <strong>Status</strong>
                      <FilterList className="filterIcon" />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  sortedData.length > 0?
                  sortedData.map((data, index) => (
                    <TableRow
                      key={index}
                      className="tableRow"
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell>{[1, 9, 10].includes(data.doc_status_id)  ? <Checkbox checked={data.export} onClick={(e) => selectSingleDoc(e, index)} />: null}</TableCell>
                      <TableCell onClick={() => handleCardClick(data)}>{data.created_on}</TableCell>
                      <TableCell onClick={() => handleCardClick(data)}>{locationMap[data.location_id] || 'N/A'}</TableCell>
                      <TableCell onClick={() => handleCardClick(data)}>{supplierMap[data.supplier_id] || 'N/A'}</TableCell>
                      <TableCell onClick={() => handleCardClick(data)}>{data.invoice_date}</TableCell>
                      <TableCell onClick={() => handleCardClick(data)}>{formatCurrency(data.total)}</TableCell>
                      <TableCell onClick={() => handleCardClick(data)}>{data.invoice_number}</TableCell>
                      <Tooltip title={data.export_msg?data.export_msg:statusMap[data.doc_status_id]} arrow><TableCell onClick={() => handleCardClick(data)}>{statusMap[data.doc_status_id] || 'N/A'}</TableCell></Tooltip>
                    </TableRow>
                  )):
                  <TableRow
                    className="tableRow"
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>No record(s) found.</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Typography variant="body2">
              Showing {startDocNum} - {endDocNum} of {totalDocuments} results
            </Typography>
          </Box>

          <Box className="paginationContainer">
            <Button
              onClick={handleFirstPageButtonClick}
              disabled={currentPage === 1}
              className="pageButton"
              startIcon={<FirstPage />}
            >
              First
            </Button>
            <Button
              onClick={handleBackButtonClick}
              disabled={currentPage === 1}
              className="pageButton"
              startIcon={<NavigateBefore />}
            >
              Back
            </Button>
            <Button
              onClick={handleNextButtonClick}
              disabled={currentPage === totalPages}
              className="pageButton"
              endIcon={<NavigateNext />}
            >
              Next
            </Button>
            <Button
              onClick={handleLastPageButtonClick}
              disabled={currentPage === totalPages}
              className="pageButton"
              endIcon={<LastPage />}
            >
              Last
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleStatusClose}
        MenuListProps={{
          'aria-labelledby': 'status-button',
        }}
      >
        {docStatuses.map((status) => (
          <MenuItem
            key={status.doc_status_id}
            selected={selectedDocStatuses.includes(status.doc_status_id)}
            onClick={() => handleStatusItemClick(status.doc_status_id)}
          >
            <Checkbox
              checked={selectedDocStatuses.includes(status.doc_status_id)}
            />
            <ListItemText primary={status.name} />
          </MenuItem>
        ))}
      </Menu>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!statusMessage.message}
        autoHideDuration={6000}
        onClose={() => setStatusMessage({ type: '', message: '' })}
      >
        <Alert onClose={() => setStatusMessage({ type: '', message: '' })} severity={statusMessage.type} sx={{ width: '100%' }}>
          {statusMessage.message}
        </Alert>
      </Snackbar>
    </div>

    
  );
};

export default ExportGridView;